import React from 'react';
import {withRouter,Link} from "react-router-dom";

class BlogsSearch extends React.Component{

    state = {
        lang: ''
    }

    componentDidMount(){
        this.setLang(this.whatPage());
    }

    setLang = (isPage) => {
        let lang = '';

        if (isPage.match(/^\/ar/gm)) { 
            lang = 'ar';
        }
        
        if(lang) {
            this.setState({lang: '/' + lang});
        }else{
            this.setState({lang: ''});
        }
    }

    whatPage = () => {
        const {pathname} =  this.props.location;
        return pathname;
    }

    renderRightSection = (lang) => {
        const {title, link} = this.props;
        return (
           <div className="search-blog-result">
               <p>{title}</p>
               <Link to={link}>{(lang==="ar")? 'دخول الصفحة': "Go To Page"}</Link>
           </div> 
        );
    }

    render (){
        const {lang} = this.state;
        return (
            <div className="media-page ">
                {this.renderRightSection(lang)}
            </div>
        );
    }
}

export default withRouter(props => <BlogsSearch {...props}/>);