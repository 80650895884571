import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';


 
function createData(event, date, cash_ar, cash, fiscal) {
  return { event, date, cash_ar, cash, fiscal };
}

const dataMapping = (data, lang) =>{
  const rows = data.map((row,i) => createData(row.event, row.agm_date, row.cash_dividend_ar, row.cash_dividend, row["fiscal_year_" + lang]))
  return rows
}


export default function BasicTable(props) {
  const {data, lang}= props; 
  return (
    <TableContainer className="table-styles">
      <Table  aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell><h2 className="bold">{(lang === "ar")? " السنة":"Event"}</h2></TableCell>
            <TableCell><h2 className="bold">{(lang === "ar")? "تاريخ الجمعية العمومية":"Agm Date"}</h2></TableCell>
            <TableCell><h2 className="bold">{(lang === "ar")? "أرباح نقدية":"Cash Dividends"}</h2></TableCell>
            <TableCell><h2 className="bold">{(lang === "ar")? "السنة المالية":"Fiscal Year"}</h2></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dataMapping(data, lang).map((row,i) => (
            <TableRow key={row.event+i}>
              <TableCell>{row.event}</TableCell>
              <TableCell>{row.date}</TableCell>
              <TableCell>{(lang === "ar")? row.cash_ar : row.cash }</TableCell>
              <TableCell>{row.fiscal}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
