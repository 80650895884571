import React from 'react';
import {withRouter, Link} from "react-router-dom";
import Api from './../../../api/api';
import Loader from "react-loader-spinner";

import ArticleComponent from './../../article-component';

class MediaCenter extends React.Component{

    state = {
        data: null,
        lang:"",
        langUrl:''
    }

    whatPage = () => {
        const {pathname} = this.props.location;
        return pathname;
    }

    setLang = (whatPage) => {
        let lang = 'en';
        if (whatPage.match(/^\/ar/gm)) { 
            lang = 'ar';
            document.title ="أحدث الأخبار والأرشيفات / الارجان / تطوير عقاري";
        }
        else{
            document.title ="Media Center / Alargan / Real Estate Development";
        }

        this.setState({lang: lang, langUrl:(lang==="en")? '' : '/ar'});
    }

    componentDidMount(){
        const whatPage = this.whatPage();
        this.setLang(whatPage);
        
        Api.getAllCenterMedias().then((response)=>{
            this.setState({data: response.data})
        });
        
    }
    
    renderArticles = (data, lang) => {
        const {center_medias} = data;
        return (
            <div >
            {center_medias.map((item,i)=>{
                const linkIcon = "→";
                return (
                    <ArticleComponent
                        key={"title-" + i}
                        title={item["title_" + lang]}
                        link={item.media_link}
                        linkIcon={linkIcon}
                    />
                )
            })}
            </div>
        )
    }
    
    renderPressKit = (data, lang) => {
        const {press_kits} = data;

        return (
            <div className="press-kit">
                <h2 className="bold">Press Kit</h2>
                {press_kits.map((item,i)=>{
                    const linkIcon=(lang==="en")? "Download": "تحميل";
                    return (
                        <ArticleComponent
                            key={"press-kits-" + i}
                            title={item["title_" + lang]}
                            link={item.download_link}
                            linkIcon={linkIcon}
                        />
                    )
                })}
            </div>
        )
    }

    renderPressReleases = (data, lang) => {
        const {press_releases} = data
        const pressReleases = press_releases.map((item) => {
            return {
                date:item.published_at,
                title:item["title_" +lang],
                link:item.link
            }
        }) 

        return(
            <div className="press-section">
                <div className="press-section-title">
                    <h2 className="bold">{(lang==="ar")? "تصريحات صحفيه":"PRESS RELEASES"}</h2>
                    <input type="text" className="search-input d-none" placeholder="SEARCH" />
                </div>
                <div className="press-section-articles">
                    {pressReleases.map((article,i)=>{
                        const {title, date, link}= article;
                        return (
                            <div key={title+"-"+i} className="press-section-articles-wrapper">
                                <div className="press-section-articles-info">
                                    <p>{date}</p>
                                    <h2 className="bold">{title}</h2>
                                </div>
                                <div className="press-section-articles-link">
                                    <Link to={link}>{(lang==="ar")? "قراءة المزيد" : "Read More"}</Link>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        );
    }

    render (){
        const {data, lang} = this.state;
        return (
           
            <div className="posts-section">
            {(data)? 
            <>
                <img src={data.data[0].media_url} alt="media img"/>
                {this.renderArticles(data,lang)}
                {this.renderPressReleases(data, lang)}
                {this.renderPressKit(data, lang)}
                <div className="press-inquiries">
                    <h1>{(lang==="en")? "For All Your Press Inquiries,":"لجميع الاستفسارات الصحفية ،"}<br/>{(lang==="en")? "Please Contact Us":"يرجى الاتصال بالمسؤول الصحفي لدينا"} </h1>
                    {/* <span>{(lang==="en")? "Ms Elena Alayan":"السيدة إيلينا عليان"}</span> */}
                    <Link to="tel:+96522263222">T: +965 2226 3222</Link>
                    <Link to="mailto:press@alargan.com">press@alargan.com</Link>
                </div>
            </>: <Loader className="loader" type="TailSpin" color="#80008D" height={100} width={100}/>}
            </div>
        );
    }
}

export default withRouter(props=> <MediaCenter {...props}/>) ;