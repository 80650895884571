import React from 'react';
import {Link} from "react-router-dom";

class ArticleComponent extends React.Component{

    render (){
        const {title, link, linkIcon, className} = this.props;
        return (
            <div className={`article-section ${className || ""}`}>
                    <div className="article-section-details">
                        <div><span>{title}</span> <a target="_blank" rel="noreferrer" href={link}>{linkIcon}</a></div>
                    </div>
            </div>
            
        );
    }
}

export default ArticleComponent;