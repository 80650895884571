import React from 'react';

class ListSection extends React.Component{

    render (){
        const {listDetails} = this.props;
        return (
            <div className="list-wrapper">
                {listDetails.map((detail , i)=>{
                    const {number, text}= detail;
                    return (
                        <div className="list-details" key={"list-" + i}>
                            <span className="number">{number}</span>
                            <span className="text">{text}</span>
                        </div>
                    )
                })}
            </div>
        );
    }
}

export default ListSection;