import React from 'react';
import ArticleComponent from './../article-component';
import {withRouter} from "react-router-dom";
import Loader from "react-loader-spinner";
import Api from './../../api/api';

class CandidateApplication extends React.Component{


    state = {
        data: null,
        lang:"",
    }

    whatPage = () => {
        const {pathname} = this.props.location;
        return pathname;
    }

    setLang = (whatPage) => {
        let lang = 'en';
        if (whatPage.match(/^\/ar/gm)) { 
            lang = 'ar';
            document.title ="طلب ترشح / الارجان / تطوير عقاري";
        }
        else{
            document.title ="CANDIDATE APPLICATION FORM / Alargan / Real Estate Development";
        }

        this.setState({lang: lang});
    }

    componentDidMount(){
        const whatPage = this.whatPage();
        this.setLang(whatPage);
        
        Api.getAllCandidateApplication().then((response)=>{
            this.setState({data: response.data})
        });
        
    }

    render (){ 
        const {data, lang} = this.state;
        let no_results;
        if(lang == 'en') {
            no_results = 'No open applications';
        } else {
            no_results ='لا يوجد طلب ترشح';
        }

        return (
            <div className="coverage-sections">
                {(data)? 
                    <>
                        {/* <h2 className="bold"></h2> */}
                        {/* <span>{data.candidate_application_data[0]["value_" + lang]}</span> */}
                        <div className="coverage-downloads-section">
                            {data.candidate_application.map((item,i)=>{
                                const className = (i === 0)? "first-item": "";
                                return (
                                    <ArticleComponent
                                        key={item["title_" + lang] + "-" + i}
                                        title={item["title_" + lang]}
                                        link={item.download_link}
                                        linkIcon={(lang === "ar")? "تحميل":"Download"}
                                        className={className}
                                    />
                                )
                            })}
                            {data.candidate_application == '' && (
                                <>{no_results}</>
                            )}
                        </div>
                    </>
                : 
                <Loader className="loader" type="TailSpin" color="#80008D" height={100} width={100} />}
            </div>
        );
    }
}

export default withRouter(props => <CandidateApplication {...props}/>);