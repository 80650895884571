import React from 'react';
import {withRouter, Link} from "react-router-dom";

import {PageSection, Left,  Right} from './../components/pageSection/pageSection';
import Overview from './../components/investor/overview';
import KeyFinancials from './../components/investor/keyFinancials';
import Disclosures from './../components/investor/disclosures';
import CreditRating from './../components/investor/creditRating';
import Documents from './../components/investor/documents';
import Coverage from './../components/investor/coverage';
import Calendar from './../components/investor/calendar';
import CandidateApplication from './../components/investor/candidateApplication';
import Shareholder from './../components/investor/shareholder/shareholder';

class Investor extends React.Component{

    state = {
        lang: ''
    }

    componentDidMount(){
        this.setLang(this.whatPage());
    }

    setLang = (isPage) => {
        let lang = '';

        if (isPage.match(/^\/ar/gm)) { 
            lang = 'ar';
        }
        
        if(lang) {
            this.setState({lang: '/' + lang});
        }else{
            this.setState({lang: ''});
        }
    }

    whatPage = () => {
        const {path} =  this.props.match;
        return path;
      }

    renderLinks = () => {
        const {lang} = this.state;
        const page = this.whatPage();
        switch(page) {
            default:
            case "/ar/investor":
            case "/investor":
                return(
                    <div className="link-section">
                        <strong>{lang? "نظرة عامة" : "OVERVIEW"}</strong>
                        <Link to={lang + "/investor/annual-reports"}>{lang? "التقارير السنوية" : "ANNUAL REPORTS"}</Link>
                        <Link to={lang + "/investor/disclosures"}>{lang? "إفصاحات" : "DISCLOSURES"}</Link>
                        <Link to={lang + "/investor/credit-rating"}>{lang? "التصنيف الائتماني" : "CREDIT RATING"}</Link>
                        <Link to={lang + "/investor/agm-documents"}>{lang? "وثائق الجمعية العمومية" : "AGM DOCUMENTS"}</Link>
                        <Link to={lang + "/investor/shareholders"}>{lang? "المساهمون وتوزيع الأرباح" : "SHAREHOLDERS & DIVIDENDS"}</Link>
                        <Link to={lang + "/investor/analyst"}>{lang? "تقارير المحللين" : "ANALYST COVERAGE"}</Link>
                        {/* <Link to={lang + "/investor/calendar"}>{lang? "التقويم المالي" : "FINANCIAL CALENDAR"}</Link> */}
                        <Link to={lang +"/investor/candidate-application"}>{lang? "طلب ترشح" : "CANDIDATE APPLICATION FORM"}</Link>
                    </div>
                );
            case "/ar/investor/annual-reports":
            case "/investor/annual-reports":
                return(
                    <div className="link-section">
                        <Link to={lang + "/investor"}>{lang? "نظرة عامة" : "OVERVIEW"}</Link>
                        <strong>{lang? "التقارير السنوية" : "ANNUAL REPORTS"}</strong>
                        <Link to={lang + "/investor/disclosures"}>{lang? "إفصاحات" : "DISCLOSURES"}</Link>
                        <Link to={lang + "/investor/credit-rating"}>{lang? "التصنيف الائتماني" : "CREDIT RATING"}</Link>
                        <Link to={lang + "/investor/agm-documents"}>{lang? "وثائق الجمعية العمومية" : "AGM DOCUMENTS"}</Link>
                        <Link to={lang + "/investor/shareholders"}>{lang? "المساهمون وتوزيع الأرباح" : "SHAREHOLDERS & DIVIDENDS"}</Link>
                        <Link to={lang + "/investor/analyst"}>{lang? "تقارير المحللين" : "ANALYST COVERAGE"}</Link>
                        {/* <Link to={lang + "/investor/calendar"}>{lang? "التقويم المالي" : "FINANCIAL CALENDAR"}</Link> */}
                        <Link to={lang +"/investor/candidate-application"}>{lang? "طلب ترشح" : "CANDIDATE APPLICATION FORM"}</Link>
                    </div>
                );
            case "/ar/investor/disclosures":
            case "/investor/disclosures":
                return(
                    <div className="link-section">
                        <Link to={lang + "/investor"}>{lang? "نظرة عامة" : "OVERVIEW"}</Link>
                        <Link to={lang + "/investor/annual-reports"}>{lang? "التقارير السنوية" : "ANNUAL REPORTS"}</Link>
                        <strong>{lang? "إفصاحات" : "DISCLOSURES"}</strong>
                        <Link to={lang + "/investor/credit-rating"}>{lang? "التصنيف الائتماني" : "CREDIT RATING"}</Link>
                        <Link to={lang + "/investor/agm-documents"}>{lang? "وثائق الجمعية العمومية" : "AGM DOCUMENTS"}</Link>
                        <Link to={lang + "/investor/shareholders"}>{lang? "المساهمون وتوزيع الأرباح" : "SHAREHOLDERS & DIVIDENDS"}</Link>
                        <Link to={lang + "/investor/analyst"}>{lang? "تقارير المحللين" : "ANALYST COVERAGE"}</Link>
                        {/* <Link to={lang + "/investor/calendar"}>{lang? "التقويم المالي" : "FINANCIAL CALENDAR"}</Link> */}
                        <Link to={lang +"/investor/candidate-application"}>{lang? "طلب ترشح" : "CANDIDATE APPLICATION FORM"}</Link>
                    </div>
                );
            case "/ar/investor/credit-rating":
            case "/investor/credit-rating":
                return(
                    <div className="link-section">
                        <Link to={lang + "/investor"}>{lang? "نظرة عامة" : "OVERVIEW"}</Link>
                        <Link to={lang + "/investor/annual-reports"}>{lang? "التقارير السنوية" : "ANNUAL REPORTS"}</Link>
                        <Link to={lang + "/investor/disclosures"}>{lang? "إفصاحات" : "DISCLOSURES"}</Link>
                        <strong>{lang? "التصنيف الائتماني" : "CREDIT RATING"}</strong>
                        <Link to={lang + "/investor/agm-documents"}>{lang? "وثائق الجمعية العمومية" : "AGM DOCUMENTS"}</Link>
                        <Link to={lang + "/investor/shareholders"}>{lang? "المساهمون وتوزيع الأرباح" : "SHAREHOLDERS & DIVIDENDS"}</Link>
                        <Link to={lang + "/investor/analyst"}>{lang? "تقارير المحللين" : "ANALYST COVERAGE"}</Link>
                        {/* <Link to={lang + "/investor/calendar"}>{lang? "التقويم المالي" : "FINANCIAL CALENDAR"}</Link> */}
                        <Link to={lang +"/investor/candidate-application"}>{lang? "طلب ترشح" : "CANDIDATE APPLICATION FORM"}</Link>
                    </div>
                );               
            case "/ar/investor/agm-documents":
            case "/investor/agm-documents":
                return(
                    <div className="link-section">
                        <Link to={lang + "/investor"}>{lang? "نظرة عامة" : "OVERVIEW"}</Link>
                        <Link to={lang + "/investor/annual-reports"}>{lang? "التقارير السنوية" : "ANNUAL REPORTS"}</Link>
                        <Link to={lang + "/investor/disclosures"}>{lang? "إفصاحات" : "DISCLOSURES"}</Link>
                        <Link to={lang + "/investor/credit-rating"}>{lang? "التصنيف الائتماني" : "CREDIT RATING"}</Link>
                        <strong>{lang? "وثائق الجمعية العمومية" : "AGM DOCUMENTS"}</strong>
                        <Link to={lang + "/investor/shareholders"}>{lang? "المساهمون وتوزيع الأرباح" : "SHAREHOLDERS & DIVIDENDS"}</Link>
                        <Link to={lang + "/investor/analyst"}>{lang? "تقارير المحللين" : "ANALYST COVERAGE"}</Link>
                        {/* <Link to={lang + "/investor/calendar"}>{lang? "التقويم المالي" : "FINANCIAL CALENDAR"}</Link> */}
                        <Link to={lang +"/investor/candidate-application"}>{lang? "طلب ترشح" : "CANDIDATE APPLICATION FORM"}</Link>
                    </div>
                );                
            case "/ar/investor/shareholders":
            case "/investor/shareholders":
                return(
                    <div className="link-section">
                        <Link to={lang + "/investor"}>{lang? "نظرة عامة" : "OVERVIEW"}</Link>
                        <Link to={lang + "/investor/annual-reports"}>{lang? "التقارير السنوية" : "ANNUAL REPORTS"}</Link>
                        <Link to={lang + "/investor/disclosures"}>{lang? "إفصاحات" : "DISCLOSURES"}</Link>
                        <Link to={lang + "/investor/credit-rating"}>{lang? "التصنيف الائتماني" : "CREDIT RATING"}</Link>
                        <Link to={lang + "/investor/agm-documents"}>{lang? "وثائق الجمعية العمومية" : "AGM DOCUMENTS"}</Link>
                        <strong>{lang? "المساهمون وتوزيع الأرباح" : "SHAREHOLDERS & DIVIDENDS"}</strong>
                        <Link to={lang + "/investor/analyst"}>{lang? "تقارير المحللين" : "ANALYST COVERAGE"}</Link>
                        {/* <Link to={lang + "/investor/calendar"}>{lang? "التقويم المالي" : "FINANCIAL CALENDAR"}</Link> */}
                        <Link to={lang +"/investor/candidate-application"}>{lang? "طلب ترشح" : "CANDIDATE APPLICATION FORM"}</Link>

                    </div>
                );
            case "/ar/investor/analyst":
            case "/investor/analyst":
                return(
                    <div className="link-section">
                        <Link to={lang + "/investor"}>{lang? "نظرة عامة" : "OVERVIEW"}</Link>
                        <Link to={lang + "/investor/annual-reports"}>{lang? "التقارير السنوية" : "ANNUAL REPORTS"}</Link>
                        <Link to={lang + "/investor/disclosures"}>{lang? "إفصاحات" : "DISCLOSURES"}</Link>
                        <Link to={lang + "/investor/credit-rating"}>{lang? "التصنيف الائتماني" : "CREDIT RATING"}</Link>
                        <Link to={lang + "/investor/agm-documents"}>{lang? "وثائق الجمعية العمومية" : "AGM DOCUMENTS"}</Link>
                        <Link to={lang + "/investor/shareholders"}>{lang? "المساهمون وتوزيع الأرباح" : "SHAREHOLDERS & DIVIDENDS"}</Link>
                        <strong>{lang? "تقارير المحللين" : "ANALYST COVERAGE"}</strong>
                        {/* <Link to={lang + "/investor/calendar"}>{lang? "التقويم المالي" : "FINANCIAL CALENDAR"}</Link> */}
                        <Link to={lang +"/investor/candidate-application"}>{lang? "طلب ترشح" : "CANDIDATE APPLICATION FORM"}</Link>
                    </div>
                );                
            case "/ar/investor/calendar":
            case "/investor/calendar":
                return(
                    <div className="link-section">
                        <Link to={lang + "/investor"}>{lang? "نظرة عامة" : "OVERVIEW"}</Link>
                        <Link to={lang + "/investor/annual-reports"}>{lang? "التقارير السنوية" : "ANNUAL REPORTS"}</Link>
                        <Link to={lang + "/investor/disclosures"}>{lang? "إفصاحات" : "DISCLOSURES"}</Link>
                        <Link to={lang + "/investor/credit-rating"}>{lang? "التصنيف الائتماني" : "CREDIT RATING"}</Link>
                        <Link to={lang + "/investor/agm-documents"}>{lang? "وثائق الجمعية العمومية" : "AGM DOCUMENTS"}</Link>
                        <Link to={lang + "/investor/shareholders"}>{lang? "المساهمون وتوزيع الأرباح" : "SHAREHOLDERS & DIVIDENDS"}</Link>
                        <Link to={lang + "/investor/analyst"}>{lang? "تقارير المحللين" : "ANALYST COVERAGE"}</Link>
                        {/* <strong>{lang? "التقويم المالي" : "FINANCIAL CALENDAR"}</strong> */}
                        <Link to={lang +"/investor/candidate-application"}>{lang? "طلب ترشح" : "CANDIDATE APPLICATION FORM"}</Link>
                    </div>
                );
                case "/ar/investor/candidate-application":
                case "/investor/candidate-application":
                    return(
                        <div className="link-section">
                            <Link to={lang + "/investor"}>{lang? "نظرة عامة" : "OVERVIEW"}</Link>
                            <Link to={lang + "/investor/annual-reports"}>{lang? "التقارير السنوية" : "ANNUAL REPORTS"}</Link>
                            <Link to={lang + "/investor/disclosures"}>{lang? "إفصاحات" : "DISCLOSURES"}</Link>
                            <Link to={lang + "/investor/credit-rating"}>{lang? "التصنيف الائتماني" : "CREDIT RATING"}</Link>
                            <Link to={lang + "/investor/agm-documents"}>{lang? "وثائق الجمعية العمومية" : "AGM DOCUMENTS"}</Link>
                            <Link to={lang + "/investor/shareholders"}>{lang? "المساهمون وتوزيع الأرباح" : "SHAREHOLDERS & DIVIDENDS"}</Link>
                            <Link to={lang + "/investor/analyst"}>{lang? "تقارير المحللين" : "ANALYST COVERAGE"}</Link>
                            {/* <strong>{lang? "التقويم المالي" : "FINANCIAL CALENDAR"}</strong> */}
                            <strong>{lang? "طلب ترشح" : "CANDIDATE APPLICATION FORM"}</strong>
                        </div>
                    );
        }
    }

    renderContent = () => {
        const page = this.whatPage();
        switch(page) {
            default:
            case "/ar/investor":
            case "/investor":
                return <Overview />;

            case "/ar/investor/annual-reports":
            case "/investor/annual-reports":
                return <KeyFinancials />;

            case "/ar/investor/disclosures":
            case "/investor/disclosures":
                return <Disclosures />;

            case "/ar/investor/credit-rating":
            case "/investor/credit-rating":
                return <CreditRating />;

            case "/ar/investor/agm-documents":
            case "/investor/agm-documents":
                return <Documents />;        

            case "/ar/investor/shareholders":
            case "/investor/shareholders":
                return <Shareholder />;

            case "/ar/investor/analyst":
            case "/investor/analyst":
                return <Coverage />; 

            case "/ar/investor/calendar":
            case "/investor/calendar":
                return <Calendar />;

            case "/ar/investor/candidate-application":
            case "/investor/candidate-application":
                return <CandidateApplication />;
        }
    }

    getClassContentName = () => {
        const page = this.whatPage();
        switch(page) {
            default:
            case "/ar/investor":
            case "/investor":
                return "overview-page";

            case "/ar/investor/annual-reports":
            case "/investor/annual-reports":
                return "key-financial-page";

            case "/ar/investor/disclosures":
            case "/investor/disclosures":
                return "disclosures-page";

            case "/ar/investor/credit-rating":
            case "/investor/credit-rating":
                return "credit-rating-page";

            case "/ar/investor/agm-documents":
            case "/investor/agm-documents":
                return "agm-documents-page";

            case "/ar/investor/shareholders":
            case "/investor/shareholders":
                return "shareholders-page";

            case "/ar/investor/analyst":
            case "/investor/analyst":
                return "analyst-page";

            case "/ar/investor/candidate-application":
            case "/investor/candidate-application":
                return "calendar-page";  

            case "/ar/investor/calendar":
            case "/investor/calendar":
                return "calendar-page"; 
        }
    }

    render (){
        const {lang} = this.state;
        return (
            <div className="investor-page">
                <PageSection>   
                        <Left>
                            <strong>{lang? "علاقات المستثمرين" : "INVESTOR RELATIONS"}</strong>
                            {this.renderLinks()}
                        </Left>
                        <Right className={this.getClassContentName()}>
                            {this.renderContent()}
                        </Right>
                </PageSection>
            </div>
        );
    }
}

export default withRouter(props => <Investor {...props}/>);
