import React from 'react';
import {withRouter} from "react-router-dom";
import Api from './../../api/api';
import Loader from "react-loader-spinner";

class LeaderDetails extends React.Component{

    state = {
        data: null,
        lang:"",
    }

    whatPage = () => {
        const {pathname} = this.props.location;
        return pathname;
    }

    getLeaderId = () => {
        const {id} = this.props.match.params;
        return id;
    }

    setLang = (whatPage) => {
        let lang = 'en';
        if (whatPage.match(/^\/ar/gm)) { 
            lang = 'ar';
        }

        this.setState({lang: lang});
    }

    componentDidMount(){
        const whatPage = this.whatPage();
        this.setLang(whatPage);
        
        Api.getSingleLeadershipMemberData(this.getLeaderId()).then((response)=>{
            this.setState({data: response.data.leadership_member})
        });
        
    }

    render (){
        const {data, lang} = this.state;
        return (
            <div className="leader-details-page">
            {(data)? 
                <>
                    <div className="leader-name">
                        <h2 className="bold">{data["name_" + lang]}</h2>
                        <p>{data["role_" + lang]}</p>
                    </div>
                    <div className="leader-info">
                        <img src={data.image_url} alt="leader img"/>
                        <p className="leader-desc">{data["bio_" + lang]}</p>
                    </div>
                </>:<Loader className="loader" type="TailSpin" color="#80008D" height={100} width={100} />}
            </div>
        );
    }
}

export default withRouter(props => <LeaderDetails {...props}/>);