import React from 'react';
import {withRouter} from "react-router-dom";
import Api from './../../api/api';
import Loader from "react-loader-spinner";
import ListSection from "./../listSection";

class Overview extends React.Component{

    state = {
        data: null,
        lang:"",
    }

    whatPage = () => {
        const {pathname} = this.props.location;
        return pathname;
    }

    getLeaderId = () => {
        const {id} = this.props.match.params;
        return id;
    }

    setLang = (whatPage) => {
        let lang = 'en';
        if (whatPage.match(/^\/ar/gm)) { 
            lang = 'ar';
            document.title ="الخبرة / الارجان / تطوير عقاري";

        }
        else{
            document.title ="Expertise / Alargan / Real Estate Development";
        }

        this.setState({lang: lang});
    }

    componentDidMount(){
        const whatPage = this.whatPage();
        this.setLang(whatPage);
        
        Api.getAllExpertiseOverview().then((response)=>{
            this.setState({data: response.data.data})
        });
        
    }

    renderListSection = (data, lang) => {
        const listDetailsText = [
            {
                text_en:"Countries",
                text_ar:"بلدان"
            },
            {
                text_en:"Assets Under Management",
                text_ar:"مجموع الأصول المدارة"
            }

        ];
        const listDetails = [
            {
                number:data[2]["value_" + lang],
                text: listDetailsText[0]["text_" + lang]
            },
            {
                number:data[3]["value_" + lang],
                text: listDetailsText[1]["text_" + lang]
            }
        ];

        return <ListSection listDetails={listDetails}/>

    }

    render (){
        const listDetails = [
            {
                number:"4",
                text:"Countries"
            },
            {
                number:"$83 Million",
                text:"Assets Under Management"
            }
        ]
        const {data, lang} = this.state;
        return (
            <div className="overview-page">
            {(data)?  
                <>
                    <div className="overview-wrapper">
                        <h2 className="bold">{data[0]["value_" + lang]}</h2>
                        <span>{data[1]["value_" + lang]}</span>
                    </div>
                    {this.renderListSection(data,lang)}
                </>: <Loader className="loader" type="TailSpin" color="#80008D" height={100} width={100} />}
            </div>
        );
    }
}

export default withRouter(props => <Overview {...props}/>);