import React from 'react';
import {PageSection, Left,  Right} from './../components/pageSection/pageSection';
import {withRouter} from "react-router-dom";
import Loader from "react-loader-spinner";

import Api from './../api/api';

class Contact extends React.Component{

    state = {
        data: null,
        lang:"",
        jobSelected: 0
    }

    whatPage = () => {
        const {pathname} = this.props.location;
        return pathname;
    }

    setLang = (whatPage) => {
        let lang = 'en';
        if (whatPage.match(/^\/ar/gm)) { 
            lang = 'ar';
            document.title ="اتصل بنا / الارجان / تطوير عقاري";
        }
        else{
            document.title ="Contact Us / Alargan / Real Estate Development";
        }

        this.setState({lang: lang});
    }

    componentDidMount(){
        const whatPage = this.whatPage();
        this.setLang(whatPage);
        Api.getAllContacts().then((response)=>{
            this.setState({data: response.data.contacts})
        });
        
    }

    availableContactSections = (data, lang) => {
        const availableContact = data.map((contact)=>{
            if (lang === "en") {
                return{
                    title: contact["contact_name_" +lang],
                    company: contact["company_name_" +lang], 
                    phone: contact.phone,
                    fax: contact.fax,
                    email: contact.email,
                    "P.O. Box": (lang === 'ar' ? contact["po_box_ar"] : contact["po_box"]),
                    location: <>{contact["address_line_1_" +lang]},<br/>{contact["address_line_2_" +lang]},<br/>{contact["city_" +lang]}<br/>{contact["country_" +lang]}</>
                }
            }else{
                return{
                    title: contact["contact_name_" +lang],
                    company: contact["company_name_" +lang], 
                    "رقم الهاتف": contact.phone_ar,
                    "الفاكس": contact.fax_ar,
                    "البريد الالكتروني": contact.email,
                    "صندوق البريد": (lang === 'ar' ? contact["po_box_ar"] : contact["po_box"]),
                    location: <>{contact["address_line_1_" +lang]},<br/>{contact["address_line_2_" +lang]},<br/>{contact["city_" +lang]}<br/>{contact["country_" +lang]}</>
                }
            }
        })
        

        return(
            <div className="available-contact">
                {availableContact.map((contact, i) => {
                    return (
                        <div key={"contact-" + i} className="available-contact-wrapper">
                            <h2 className="contact-title bold">{contact.title}</h2>
                            <div className="available-contact-container">
                                <div className="available-contact-left">
                                    {Object.keys(contact).map((key, i) => {
                                        if(!(key === "title" || key === "location")){
                                            if(key === "company"){
                                                return <p key={key + i} className="contact-company">{contact[key]}</p>
                                            }
                                            return (contact[key])?<p key={key + i} className={"contact-"+key}>{key + ": " + contact[key]}</p>:''
                                        }
                                        return ""
                                    })}
                                </div>
                                <div className="available-contact-right">
                                    <p className="contact-location">{contact.location}</p>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        );
    }

    render (){
        const {data, lang} = this.state;
        return (
            <div className="contact-page ">
                <PageSection>
                        <Left>
                            <strong>{(lang === "ar")? "اتصل بنا" : "CONTACT US"}</strong>
                        </Left>
                        <Right>
                        {(data)? this.availableContactSections(data, lang): <Loader className="loader" type="TailSpin" color="#80008D" height={100} width={100}/> }
                        </Right>
                </PageSection>
            </div>
        );
    }
}

export default withRouter (props => <Contact {...props}/>);
