import React from 'react';
import {withRouter,Link} from "react-router-dom";


class CategoryCard extends React.Component{

    state = {
        lang:"",
    }

    whatPage = () => {
        const {pathname} = this.props.location;
        return pathname;
    }

    setLang = (whatPage) => {
        let lang = 'en';
        if (whatPage.match(/^\/ar/gm)) { 
            lang = 'ar';
        }

        this.setState({lang: lang, langUrl:(lang==="en")? '' : '/ar'});

    }

    componentDidMount(){
        const whatPage = this.whatPage();
        this.setLang(whatPage);
    }

    render (){
        const {type, image, title, link} = this.props;
        const {lang} =this.state;
        return (
            <div className="cat-card">
                <h2 className="cat-card-type"> {type}</h2>
                <div className="cat-card-image">
                    <img src={image} alt="cat card img"/>
                </div>    
                <div className="cat-card-text">
                    <h2 className="cat-card-title bold">{title}</h2>
                    <Link className="cat-card-link" to={link}>{(lang === "en")? "Explore" :"أكتشف"}</Link>
                </div>
            </div>
        );
    }
}

export default withRouter(props => <CategoryCard {...props}/>);

