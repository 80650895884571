import React from 'react';
import {PageSection, Left,  Right} from './../components/pageSection/pageSection';
import {TextField, Select, MenuItem} from '@material-ui/core';
import {withRouter, Link} from "react-router-dom";
import Loader from "react-loader-spinner";

import Api from './../api/api';


class Careers extends React.Component{

    state = {
        data: null,
        lang:"",
        jobSelected: 0,
        userContactInfo:{
            "email":"",
            "phone":'',
            "fullname":'',
            "job_vacancy":'',
            "resume":''
        },
        isFormFilled:true,

    }

    whatPage = () => {
        const {pathname} = this.props.location;
        return pathname;
    }

    setLang = (whatPage) => {
        let lang = 'en';
        if (whatPage.match(/^\/ar/gm)) { 
            lang = 'ar';
            document.title ="الوظائف / الارجان / تطوير عقاري";
        }
        else{
            document.title ="Careers / Alargan / Real Estate Development";
        }

        this.setState({lang: lang});
    }

    componentDidMount(){
        const whatPage = this.whatPage();
        this.setLang(whatPage);
        Api.getAllJobs().then((response)=>{
            this.setState({data: response.data})
        });
        
    }

    plusIcon = () => {
        return "+";
    }

    
    createMarkup = (data) => {
        return {__html: data};
    }

    availablePositionSection = () => {

        const {data, lang}= this.state;
        const jobs = data.jobs;
        return(
            <div className="available-pos d-none">
                <h2>{(lang ==="ar")? "الوظائف المتاحة" : "Available Positions"}</h2>
                <div className="available-pos-container">
                    {jobs.map((item) => {
                        return (
                            <div className="available-pos-wrapper">
                                <div className="available-pos-info">
                                    <p>{item["job_title_" + lang]}</p>
                                    {/* <p>{item["description_" + lang]}</p> */}
                                </div>
                                <div className="align-right">
                                    <Link to="/#">{(lang ==="ar")? "اقرا المزيد" : "Read More"}</Link>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        );
    }

    setUserData = (title, data) =>{
        const {userContactInfo, lang} = this.state;
        if(title === 'resume'){
            if(data.type !== 'application/pdf') {
                const errorMsg = lang === 'ar'?  'تحميل ملف PDF' : 'Upload PDF file';
                this.setState({userContactInfo: {...userContactInfo, [title] : ''}, errorMsg});
                return;
            }else{
                this.setState({errorMsg: ''});
            }
        }

        
        this.setState({userContactInfo: {...userContactInfo, [title] : data}});
    }

    formDataValidation = (data) =>{
        return (Object.values(data).filter((val) => {
            return val === "" 
        }).length === 0)
    }

    getJobsSelectNameFromId = (selectId) => {
        const {data, lang} = this.state;
        const selectArr = data.jobs;
        for(let i in selectArr) {
            if(selectArr[i].id === selectId){ return selectArr[i]["job_title_" + lang]}
        }
        return '';
    }

    submit = (e) => {
        e.preventDefault();
        const {jobSelected, userContactInfo} = this.state;
        const data = {...userContactInfo, job_vacancy: this.getJobsSelectNameFromId(jobSelected)};
        if (this.formDataValidation(data)) {
            Api.sendCareerForm(data);
            document.getElementById("uploadInputFile").value = "";
            this.setState({isFormFilled: true, 
                userContactInfo:{
                    "email":"",
                    "phone":'',
                    "fullname":'',
                    "job_vacancy":'',
                    "resume":''
                },
                jobSelected: '0'
            })
        }else{
            this.setState({isFormFilled : false})
        }
    }


    render (){
        const {data, lang, isFormFilled, userContactInfo: {fullname,email,phone}, errorMsg} = this.state;
        return (
            <div className="careers-page ">
                <PageSection>
                    {(data)? 
                        <>
                            <Left>
                                <strong>{(lang ==="ar")? "الوظائف" : "CAREERS"}</strong>
                                <p>{(lang ==="ar")? "إنضم إلى عائلتنا" : "WORK WITH US"}</p>
                            </Left>
                            <Right>
                                <div className="team-desc">
                                    <p dangerouslySetInnerHTML={this.createMarkup(data.careers_data["value_" + lang])} />
                                </div>
                                {this.availablePositionSection()}
                                <section className="apply-section no-padding">
                                    <div className="apply-wrapper">
                                        <h1>{(lang ==="ar")? "ارسل بياناتك" : "Apply"}</h1>
                                        <form autoComplete="off">
                                            <TextField value={fullname} onChange={(e)=>this.setUserData("fullname", e.target.value)} label={(lang ==="ar")? "أدخل اسمك الكامل" : "ENTER YOUR FULL NAME"}/>
                                            <TextField value={email} onChange={(e)=>this.setUserData("email", e.target.value)} label={(lang ==="ar")? "أدخل عنوان بريدك الالكتروني" : "ENTER YOUR EMAIL ADDRESS"} type="email"/>
                                            <TextField value={phone} onChange={(e)=>this.setUserData("phone", e.target.value)} label={(lang ==="ar")? "أدخل رقم الهاتف" : "ENTER YOUR TELEPHONE NUMBER"} type="tel"/>
                                            <Select onChange={(e)=>this.setState({jobSelected: e.target.value})} value={this.state.jobSelected} IconComponent={this.plusIcon} className="select-field">
                                                <MenuItem value="0">{(lang ==="ar")? "اختر الوظيفة الشاغرة" : "SELECT JOB VACANCY"}</MenuItem>
                                                {data.jobs.map((item, i) => {
                                                    return <MenuItem value={item.id}>{item["job_title_" + lang]}</MenuItem>
                                                })}
                                            </Select>
                                            <TextField id="uploadInputFile" onChange={(e)=>this.setUserData("resume", e.target.files[0])} label={(lang ==="ar")? "حمل سيرتك الذاتية" : "UPLOAD YOUR CV" } type="file" accept="application/pdf"/>
                                            <div className="action-btns">
                                                {errorMsg? <p className={`error-msg`}>{errorMsg}</p> 
                                                : <p className={`error-msg  ${(isFormFilled)? 'd-none': 'd-block'} `}>{(lang === "ar")? 'املأ كل المعلومات' : 'Fill all the fields'}</p>
                                                }
                                                <button onClick={(e)=>this.submit(e)} className="submit-btn">{(lang ==="ar")? "إرسال" : "SUBMIT"}</button>
                                            </div>
                                        </form>
                                    </div>
                                </section> 
                            </Right>
                        </>
                        : <Loader className="loader" type="TailSpin" color="#80008D" height={100} width={100} /> }
                </PageSection>
            </div>
        );
    }
}

export default withRouter(props => <Careers {...props}/>);
