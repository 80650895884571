import React from 'react';
import {withRouter, Link} from "react-router-dom";

import {PageSection, Left,  Right} from './../components/pageSection/pageSection';
import Profile from './../components/firm/profile';
import History from './../components/firm/history';
import Sustainability from './../components/firm/sustainability';
import Social from './../components/firm/social';
import Awards from './../components/firm/awards';
import Leadership from './../components/firm/leadership';
import LeaderDetails from './../components/firm/leaderDetails';

class Firm extends React.Component{

    state = {
        lang: ''
    }

    componentDidMount(){
        this.setLang(this.whatPage());
    }

    setLang = (isPage) => {
        let lang = '';

        if (isPage.match(/^\/ar/gm)) { 
            lang = 'ar';
        }
        
        if(lang) {
            this.setState({lang: '/' + lang});
        }else{
            this.setState({lang: ''});
        }
    }

    whatPage = () => {
        const {path} =  this.props.match;
        return path;
      }

    renderLinks = () => {
        const {lang} = this.state;
        const page = this.whatPage();
        switch(page) {
            default:
            case "/ar/firm":
            case "/firm":
                return(
                    <div className="link-section">
                        <strong>{lang? "نبذة عن الشركة" : "PROFILE"}</strong>
                        <Link to={lang +"/firm/history"}>{lang? "نبذة تاريخية" : "HISTORY"}</Link>
                        <Link to={lang +"/firm/sustainability"}>{lang? "الاستدامة" : "SUSTAINABILITY"}</Link>
                        <Link to={lang +"/firm/social"}>{lang? "المسئولية الإجتماعية" : "SOCIAL RESPONSIBILITY"}</Link>
                        <Link to={lang +"/firm/awards"}>{lang? "الجوائز والشهادات التقديرية" : "AWARDS & RECOGNITIONS"}</Link>
                        <Link to={lang +"/firm/leadership"}>{lang? "الإدارة" : "LEADERSHIP"}</Link>
                    </div>
                );
            case "/ar/firm/history":
            case "/firm/history":
                return(
                    <div className="link-section">
                        <Link to={lang + "/firm"}>{lang? "نبذة عن الشركة" : "PROFILE"}</Link>
                        <strong>{lang? "نبذة تاريخية" : "HISTORY"}</strong>
                        <Link to={lang +"/firm/sustainability"}>{lang? "الاستدامة" : "SUSTAINABILITY"}</Link>
                        <Link to={lang +"/firm/social"}>{lang? "المسئولية الإجتماعية" : "SOCIAL RESPONSIBILITY"}</Link>
                        <Link to={lang +"/firm/awards"}>{lang? "الجوائز والشهادات التقديرية" : "AWARDS & RECOGNITIONS"}</Link>
                        <Link to={lang +"/firm/leadership"}>{lang? "الإدارة" : "LEADERSHIP"}</Link>
                    </div>
                );
                case "/ar/firm/sustainability":
                case "/firm/sustainability":
                return(
                    <div className="link-section">
                        <Link to={lang + "/firm"}>{lang? "نبذة عن الشركة" : "PROFILE"}</Link>
                        <Link to={lang +"/firm/history"}>{lang? "نبذة تاريخية" : "HISTORY"}</Link>
                        <strong>{lang? "الاستدامة" : "SUSTAINABILITY"}</strong>
                        <Link to={lang +"/firm/social"}>{lang? "المسئولية الإجتماعية" : "SOCIAL RESPONSIBILITY"}</Link>
                        <Link to={lang +"/firm/awards"}>{lang? "الجوائز والشهادات التقديرية" : "AWARDS & RECOGNITIONS"}</Link>
                        <Link to={lang +"/firm/leadership"}>{lang? "الإدارة" : "LEADERSHIP"}</Link>
                    </div>
                );
                case "/ar/firm/social":
                case "/firm/social":
                return(
                    <div className="link-section">
                        <Link to={lang + "/firm"}>{lang? "نبذة عن الشركة" : "PROFILE"}</Link>
                        <Link to={lang +"/firm/history"}>{lang? "نبذة تاريخية" : "HISTORY"}</Link>
                        <Link to={lang +"/firm/sustainability"}>{lang? "الاستدامة" : "SUSTAINABILITY"}</Link>
                        <strong>{lang? "المسئولية الإجتماعية" : "SOCIAL RESPONSIBILITY"}</strong>
                        <Link to={lang +"/firm/awards"}>{lang? "الجوائز والشهادات التقديرية" : "AWARDS & RECOGNITIONS"}</Link>
                        <Link to={lang +"/firm/leadership"}>{lang? "الإدارة" : "LEADERSHIP"}</Link>
                    </div>
                );
                case "/ar/firm/awards":
                case "/firm/awards":
                return(
                    <div className="link-section">
                        <Link to={lang + "/firm"}>{lang? "نبذة عن الشركة" : "PROFILE"}</Link>
                        <Link to={lang +"/firm/history"}>{lang? "نبذة تاريخية" : "HISTORY"}</Link>
                        <Link to={lang +"/firm/sustainability"}>{lang? "الاستدامة" : "SUSTAINABILITY"}</Link>
                        <Link to={lang +"/firm/social"}>{lang? "المسئولية الإجتماعية" : "SOCIAL RESPONSIBILITY"}</Link>
                        <strong>{lang? "الجوائز والشهادات التقديرية" : "AWARDS & RECOGNITIONS"}</strong>
                        <Link to={lang +"/firm/leadership"}>{lang? "الإدارة" : "LEADERSHIP"}</Link>
                    </div>
                );
                case "/ar/firm/leadership":
                case "/firm/leadership":
                return(
                    <div className="link-section">
                        <Link to={lang + "/firm"}>{lang? "نبذة عن الشركة" : "PROFILE"}</Link>
                        <Link to={lang +"/firm/history"}>{lang? "نبذة تاريخية" : "HISTORY"}</Link>
                        <Link to={lang +"/firm/sustainability"}>{lang? "الاستدامة" : "SUSTAINABILITY"}</Link>
                        <Link to={lang +"/firm/social"}>{lang? "المسئولية الإجتماعية" : "SOCIAL RESPONSIBILITY"}</Link>
                        <Link to={lang +"/firm/awards"}>{lang? "الجوائز والشهادات التقديرية" : "AWARDS & RECOGNITIONS"}</Link>
                        <strong>{lang? "الإدارة" : "LEADERSHIP"}</strong>
                    </div>
                );
                case "/ar/firm/leadership/:id":
                case "/firm/leadership/:id":
                return(
                    <div className="link-section">
                        <Link to={lang + "/firm"}>{lang? "نبذة عن الشركة" : "PROFILE"}</Link>
                        <Link to={lang +"/firm/history"}>{lang? "نبذة تاريخية" : "HISTORY"}</Link>
                        <Link to={lang +"/firm/sustainability"}>{lang? "الاستدامة" : "SUSTAINABILITY"}</Link>
                        <Link to={lang +"/firm/social"}>{lang? "المسئولية الإجتماعية" : "SOCIAL RESPONSIBILITY"}</Link>
                        <Link to={lang +"/firm/awards"}>{lang? "الجوائز والشهادات التقديرية" : "AWARDS & RECOGNITIONS"}</Link>
                        <Link to={lang +"/firm/leadership"}><strong>{lang? "الإدارة" : "LEADERSHIP"}</strong></Link>
                    </div>
                );
        }
    }

    renderContent = () => {
        const page = this.whatPage();
        switch(page) {
            default:
            case "/ar/firm":
            case "/firm":
                return <Profile />;
            case "/ar/firm/history":
            case "/firm/history":
                return <History />;
            case "/ar/firm/sustainability":
            case "/firm/sustainability":
                return <Sustainability />;
            case "/ar/firm/social":
            case "/firm/social":
                return <Social />;
            case "/ar/firm/awards":
            case "/firm/awards":
                return <Awards />;
            case "/ar/firm/leadership":
            case "/firm/leadership":
                return <Leadership />;
            case "/ar/firm/leadership/:id":
            case "/firm/leadership/:id":
                return <LeaderDetails />;
        }
    }

    getClassContentName = () => {
        const page = this.whatPage();
        switch(page) {
            default:
            case "/ar/firm":
            case "/firm":
                return "profile-page";
            case "/ar/firm/history":
            case "/firm/history":
                return "history-page";
            case "/ar/firm/sustainability":
            case "/firm/sustainability":
                return "sustainability-page";
            case "/ar/firm/social":
            case "/firm/social":
                return "social-page";
            case "/ar/firm/awards":
            case "/firm/awards":
                return "awards-page";
            case "/ar/firm/leadership":
            case "/firm/leadership":
                return "leadership-page";
            case "/ar/firm/leadership/:id":
            case "/firm/leadership/:id":
                return "leader-details-page";
        }
    }

    render (){
        const {lang} = this.state;
        return (
            <div className="firm-page">
                <PageSection>   
                        <Left>
                            <strong>{lang? "الشركة" : "THE FIRM"}</strong>
                            {this.renderLinks()}
                        </Left>
                        <Right className={this.getClassContentName()}>
                            {this.renderContent()}
                        </Right>
                </PageSection>
            </div>
        );
    }
}

export default withRouter(props => <Firm {...props}/>);
