import React from 'react';
import {withRouter} from "react-router-dom";
import Api from './../../api/api';
import Loader from "react-loader-spinner";
import Accordion from './../accordion';

class Framework extends React.Component{

    state = {
        data: null,
        lang:"",
    }

    whatPage = () => {
        const {pathname} = this.props.location;
        return pathname;
    }

    setLang = (whatPage) => {
        let lang = 'en';
        if (whatPage.match(/^\/ar/gm)) { 
            lang = 'ar';
            document.title ="إطار العمل / الارجان / تطوير عقاري";
        }
        else{
            document.title ="Framework / Alargan / Real Estate Development";
        }

        this.setState({lang: lang});
    }

    componentDidMount(){
        const whatPage = this.whatPage();
        this.setLang(whatPage);
        
        Api.getAllFrameworks().then((response)=>{
            this.setState({data: response.data})
        });
        
    }

    renderDirectors = (data, lang) => {
        const {members} = data;

        return (
            members.map((member, i)=>{   
                return <div className="director-details"  key={"member-" + i}>
                    <p className="director-details-name" >{member["name_" + lang]}</p>
                    <p className="director-details-occupation" >{member["job_role_" + lang]}</p>
                </div>
            
            })
        )
    }

    renderMemberBlock = (data, lang) =>{
       return <>
        {data.map((section,i)=>{
            return<div>
            <h2 className="board-title">{section["name_" + lang]}</h2>
            {section.members.map((member,i)=>{
                return (
                <div className="member-details">
                    <div>
                        <p>{member["name_" + lang]}</p>
                        <p className="italic">{member["job_role_" + lang]}</p>
                    </div>
                </div>
                )
            })}
            </div>
        })}
       
        </>
     }

    renderAccordion = (response, lang) => {
        const {governance_frameworks} = response;
        const data = governance_frameworks.map((item, i)=> {
            const {teams} = item;
            return{
                title:item["title_" + lang],
                paragraph:  <div key={"framework-" + i} className="framework-details-container">
                                <span>
                                    {item["description_" + lang]}
                                </span>
                                {(teams.length === 1)?
                                    this.renderDirectors(teams[0], lang)
                                 :
                                   <div className="board-committees">{this.renderMemberBlock(teams, lang)} </div>
                                }

                            </div>
            }
        })

        return <Accordion data={data}/>;
    }

    render (){
        const {data, lang} = this.state;
        return (
            <div className="framework-page">
            {(data)? 
                <>
                    <div className="framework-img">
                        {(lang === "ar")? 
                            <img src={data.data[1].media_url} alt="framework-img"/>
                        : 
                            <img src={data.data[0].media_url} alt="framework-img"/>
                        }
                    </div>
                    {this.renderAccordion(data, lang)}
                </>
                :<Loader className="loader" type="TailSpin" color="#80008D" height={100} width={100} />}
            </div>
        );
    }
}

export default withRouter(props => <Framework {...props}/>);