import React from 'react';
import {withRouter} from "react-router-dom";
import Api from './../../../api/api';
import Loader from "react-loader-spinner";
import blogImg from "../../../assets/img/lifestyle1.png";

class LifestyleBlog extends React.Component{

    state = {
        data: null,
        lang:"",
        langUrl:''
    }

    whatPage = () => {
        const {pathname} = this.props.location;
        return pathname;
    }

    setLang = (whatPage) => {
        let lang = 'en';
        if (whatPage.match(/^\/ar/gm)) { 
            lang = 'ar';
        }

        this.setState({lang: lang, langUrl:(lang==="en")? '' : '/ar'});
    }
    getBlogId = () => {
        const {id} = this.props.match.params;
        return id;
    }

    componentDidMount(){
        const whatPage = this.whatPage();
        this.setLang(whatPage);
        
        Api.getSingleBlog(this.getBlogId()).then((response)=>{
            this.setState({data: response.data.blog})
        });
        
    }

    render (){
        const {data, lang} = this.state;
        return (
            <div className="lifestyle-blog-page">
            {(data)? <> 
                <div className="lifestyle-blog-meta">
                    <h2 className="bold">{data["title_" + lang]}</h2>
                    <span>{data.published_date}</span>
                </div>
                <div className="lifestyle-blog-content">
                    <p>{data["description_" + lang]}</p>
                    <img alt="blog img" className="lifestyle-blog-img" src={blogImg} />
                </div>
                </> : <Loader className="loader" type="TailSpin" color="#80008D" height={100} width={100}/>}
            </div>
        );
    }
}

export default withRouter(props => <LifestyleBlog {...props}/>);