import React from 'react';
import {withRouter, Link} from "react-router-dom";
import Api from './../api/api';
import Loader from "react-loader-spinner";
import {Link as ScrollLink, Events} from "react-scroll";
import fbLogo from '../assets/img/fb-icon.svg';
import igLogo from '../assets/img/ig-icon.svg';
import linkedinLogo from '../assets/img/linkedin-icon.svg';
import emailLogo from '../assets/img/email-icon.svg';
import slugify from 'react-slugify';



class PropertySinglePage extends React.Component{

    
    state = {
        data: null,
        lang:"",
    }

    whatPage = () => {
        const {pathname} = this.props.location;
        return pathname;
    }

    getProjectId = () => {
        const {id} = this.props.match.params;
        return id;
    }

    setLang = (whatPage) => {
        let lang = 'en';
        if (whatPage.match(/^\/ar/gm)) { 
            lang = 'ar';
        }

        this.setState({lang: lang});
    }

    componentDidMount(){
        const whatPage = this.whatPage();
        this.setLang(whatPage);
        
        const id = this.getProjectId();
        Api.getSingleProject(id).then((response)=>{
            this.setState({data: this.responseMapping(response.data.project)})
        });
        
    }

    responseMapping = (response) => {
        const {lang} = this.state;
        const {cover, components, developers, architects, city, types, completion_date, built_up_area, descriptions, images, name_en, id } = response;
        const baseUrl = "https://alargan.com/"+lang+"/properties/"+id+"/"+slugify(name_en);
        return {
            backgroundImg: cover[0].image_url,
            title: response["name_" + lang],
            tag: "#" + response["name_" + lang].split(" ").join(""),
            link: "/#",
            socialmedia: {
                facebook: "http://www.facebook.com/sharer.php?u=" + baseUrl,
                linkedin: "https://www.linkedin.com/shareArticle?mini=true&url=" + baseUrl,
                email: "mailto:?subject=Alargan Project&body="+ baseUrl
            },
            properties: descriptions.map((item) => { 
                return {
                    title: item["title_" + lang],
                    description: item["description_" + lang]
                }
            }),
            propertyMeta: [
                {
                    title: (lang === 'ar' ? "نبذة عن الشركة" : "PROFILE"),
                    description: developers.map((item, i) => item["name_" + lang]).join(", ")
                },
                {
                    title: (lang === 'ar' ? "المهندس المعماري" : "ARCHITECT"),
                    description: architects.map((item, i) => item["name_" + lang]).join(", ")
                },
                {
                    title: (lang === 'ar' ? "التفاصيل" : "DETAILS"),
                    description: response["details_" + lang]
                },
                {
                    title: (lang === 'ar' ? "الموقع" : "LOCATION"),
                    description: city["name_" + lang]
                },
                {
                    title: (lang === 'ar' ? "مساحة المشروع المبنية" : "PROJECT BUILT-UP AREA"),
                    // description: built_up_area + " SQM"
                    description: response["built_up_area"] + (lang === 'ar' ? 'م²' : 'SQM')
                },
                {
                    title: (lang === 'ar' ? "النوع" : "TYPE"),
                    description: types.map((item)=> item["name_" +lang]).join(', ')
                },
                {
                    title: (lang === 'ar' ? "اكتمال المشروع" : "PROJECT COMPLETION"),
                    description: (lang === 'ar' ? response["completion_date_" + lang] : response["completion_date"])
                }
            ],
            components:  components.map((item, i) => item["name_" + lang]) ,
            images: images.map((image) => {
                return {
                    image: image.image_url,
                    order: image.order,
                    size: image.size
                }
            })
        };
    }

    renderPageHeader = () => {
        const {backgroundImg, title} = this.state.data;
        const styles = {
            backgroundImage: "url(" + backgroundImg +")"
        }
        return <div className="property-single-page-header"
            style={styles}
        >
            <h1 className="property-single-page-header-title">{title}</h1>
        </div>
    }

    registerInterest = () => {
        Events.scrollEvent.register('end', function(to, element) {
            document.getElementById("name-input").focus();
        });
    }

    renderHeaderLinks = () => {
        const {tag, socialmedia: {facebook, linkedin, email} } = this.state.data;
        const {lang} = this.state;
        return <section className="property-single-page-headerlinks">
            <div className="property-single-page-headerlinks-wrapper">
                <div className="property-single-page-headerlinks-left">
                    <span className="purple medium tag">{tag}</span>
                    <ScrollLink onClick={()=>this.registerInterest()} to="contact-card" smooth={true} className="purple regular cursor-pointer" >{(lang === "ar")? "سجل اهتمامك":"Register Your Interest"}</ScrollLink>
                </div>
                <div className="property-single-page-headerlinks-right">
                    <span className="purple regular share">{(lang === "ar")? "شارك":"Share"}</span>
                    <a href={facebook} target="_blank"><img src={fbLogo} alt="facebook"/></a>
                    <a href={linkedin} target="_blank"><img src={linkedinLogo} alt="linkedin"/></a>
                    <a href={email} target="_blank"><img src={emailLogo} alt="email"/></a>
                </div>
            </div>
        </section>
    }

    renderContent = () => {
        const {properties, propertyMeta, components,lang} = this.state.data;
        return <section className="property-single-page-content">
            <div className="property-single-page-content-wrapper">
                <div className="property-single-page-content-left">
                    {properties.map(({title, description}, i) => {
                        return <div className="property-single-page-content-property" key={title + "-" + i}>
                            <h2 className="bold">{title}</h2>
                            <p>{description}</p>
                        </div>
                    })}
                </div>
                <div className="property-single-page-content-right">
                    <div className="property-single-page-content-propertymeta">
                        {propertyMeta.map(({title, description}, i) => {
                            return <div key={title + "-" + i}>
                                <strong>{title}</strong>
                                &nbsp;
                                <span>{description}</span>
                            </div>
                        })}
                    </div>
                    {(components.length !==0)?<div className="property-single-page-content-components">
                       <strong>{(lang === "ar")? "التفاصيل":"Components"}</strong>
                        {components.map((comp, i) => {
                            return <div key={"components-"+ i}>{comp}</div>
                        })}
                    </div> : ''}
                </div>
            </div>
        </section>
    } 
    
    setPageTitle = (data,lang) => {
        if (lang ==="ar"){
            document.title = data.title +" / الارجان / تطوير عقاري";
        }else{
            document.title = data.title +" / Alargan / Real Estate Development";
        }
    }
    
    renderImages = () => {
        const {images} = this.state.data;
        return <section className="property-single-page-images" id="scroll-to">
            {images.map((image,i) => {
                return <div className={`image-size-${image.size}`} style={{order: image.order}}>
                    <img src={image.image}  alt={"property-single-page-images-" + i} />
                </div>
            })}
           
        </section>
    }

    render (){
        const {data,lang} = this.state;
        return (
            <div className="property-single-page">
                {(data)? 
                
                    <>
                        {this.setPageTitle(data,lang)}
                        {this.renderPageHeader()}
                        {this.renderHeaderLinks()}
                        {this.renderContent()}
                        {this.renderImages()}
                    </>
                : <Loader className="loader" type="TailSpin" color="#80008D" height={100} width={100} />}
            </div>
        );
    }
}

export default withRouter(props => <PropertySinglePage {...props}/>);

