import React from 'react';
import {withRouter} from "react-router-dom";
import Api from './../../api/api';
import Accordion from "../accordion";
import Loader from "react-loader-spinner";


class Awards extends React.Component{


    state = {
        data: null,
        lang:"",
    }

    whatPage = () => {
        const {pathname} = this.props.location;
        return pathname;
    }

    setLang = (whatPage) => {
        let lang = 'en';
        if (whatPage.match(/^\/ar/gm)) { 
            lang = 'ar';
            document.title ="الجوائز والشهادات التقديرية / الارجان / تطوير عقاري";
        }
        else{
            document.title ="Awards / Alargan / Real Estate Development";
        }

        this.setState({lang: lang});
    }

    componentDidMount(){
        const whatPage = this.whatPage();
        this.setLang(whatPage);
        
        Api.getAllAwardsRecognitionsData().then((response)=>{
            this.setState({data: response.data})
        });
        
    }

    createMarkup = (data) => {
        return {__html: data};
    }


    renderAccordion = (response, lang) => {
        const data =response.awards_recognitions.map((item) => {
            return {
                title:item["title_" + lang], 
                paragraph:item["description_" + lang]
            };
        })

        return <Accordion data={data}/>;
    }

    render (){
        const{data, lang}= this.state;
        return (
            <>
            {(data)? 
                <>
                    <div className="social-page-text">
                        <h2 className="bold" dangerouslySetInnerHTML={this.createMarkup(data.awards_recognitions_data[0]["value_" + lang])}/>

                        {/* <span>{data.awards_recognitions_data[1]["value_" + lang]}</span> */}
                    </div>
                    {this.renderAccordion(data, lang)}
                </>
                :<Loader className="loader" type="TailSpin" color="#80008D" height={100} width={100} />}
            </>
        );
    }
}

export default withRouter(props => <Awards {...props}/>);