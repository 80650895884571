import React from 'react';
import ArticleComponent from './../article-component';
import {withRouter} from "react-router-dom";
import Api from './../../api/api';
import Loader from "react-loader-spinner";
  
class Calendar extends React.Component{


    
    state = {
        data: null,
        lang:"",
    }

    whatPage = () => {
        const {pathname} = this.props.location;
        return pathname;
    }

    setLang = (whatPage) => {
        let lang = 'en';
        if (whatPage.match(/^\/ar/gm)) { 
            lang = 'ar';
            document.title ="التقويم المالي / الارجان / تطوير عقاري";
        }
        else{
            document.title ="FINANCIAL CALENDAR / Alargan / Real Estate Development";
        }

        this.setState({lang: lang});
    }

    componentDidMount(){
        const whatPage = this.whatPage();
        this.setLang(whatPage);
        
        Api.getAllFinancialCalendar().then((response)=>{
            this.setState({data: response.data})
        });
        
    }

    renderUpcoming = (data, lang) => {
        const {upcoming} = data;
        return (
            <div className="calendar-downloads-section article-component-section-listing">
                <h2 className="bold">{(lang === "ar")? "القادمة":"Upcoming"}</h2>
                {upcoming.map((item,i)=>{
                    const className = (i === 0)? "first-item": "";
                    return (
                        <ArticleComponent
                            key={item["title_" + lang] + "-" + i}
                            title={item.date}
                            link="/#"
                            linkIcon={item["title_" + lang]}
                            className={className}
                        />
                    )
                })}
            </div>
        );
    }

    renderPast = (data, lang) => {
        const {past} = data;
        return (
            <div className="calendar-downloads-section article-component-section-listing">
                <h2 className="bold">{(lang === "ar")? "الماضي":"Past"}</h2>
                {past.map((item,i)=>{
                    const className = (i === 0)? "first-item": "";
                    return (
                        <ArticleComponent
                            key={"past-" + i}
                            title={item.date}
                            link="/#"
                            linkIcon={item["title_" + lang]}
                            className={className}
                        />
                    )
                })}
            </div>
        );
    }


    render (){
        const {data, lang} = this.state;

        return (
            <div className="calendar-sections">
                {(data)?
                    <> 
                        {this.renderUpcoming(data, lang)}
                        {this.renderPast(data, lang)}
                    </>
                :<Loader className="loader" type="TailSpin" color="#80008D" height={100} width={100} />}
            </div>
        );
    }
}

export default withRouter(props => <Calendar {...props}/>);