import React from 'react';
import {withRouter, Link} from "react-router-dom";
import Loader from "react-loader-spinner";
import slugify from 'react-slugify';

import Api from './../api/api';

import DescriptionCard from '../components/descriptionCard';
import CategoryCard from './../components/home/categoryCard';
import NewsCard from './../components/home/newsCard';

class Home extends React.Component{

    state = {
        data: null,
        lang:"",
        langUrl:'',
    }

    whatPage = () => {
        const {pathname} = this.props.location;
        return pathname;
    }

    setLang = (whatPage) => {
        let lang = 'en';
        if (whatPage.match(/^\/ar/gm)) { 
            lang = 'ar';
            document.title ="الارجان / تطوير عقاري";
        }
        else{
            document.title ="Alargan / Real Estate Development";
        }

        this.setState({lang: lang, langUrl:(lang==="en")? '' : '/ar'});

    }

    componentDidMount(){
        const whatPage = this.whatPage();
        this.setLang(whatPage);
        
        Api.getHome().then((response)=>{
            this.setState({data: response.data.data})
        });
    }

    renderCategorySection = (data, lang) => {
        const {sectors} = data;
        return (
            <section className="category-section section">
                {sectors.map((details,i)=>{
                    return (
                        <CategoryCard 
                            key={'catDetails-' + i}
                            type={details["title_" +lang]}
                            image={details.image_url}
                            title={details["sector_name_" + lang]}
                            link={this.state.langUrl+"/properties/" + details.project_id + "/" + slugify(details.name_en)}
                        />
                    )
                })}
            </section>
        );
    }

    renderAlarganLife = (data, lang, langUrl) => {
        const {alargan_life} = data;
        return (
            <section className="alargan-life-section">
                <div className="alargan-life-wrapper">
                    <div className="alargan-life-content">
                        <span className="life">{(lang === "en")? "LIFE AS" :"الحياة"} <br/> {(lang === "en")? "YOU LOVE IT" :"كما تحبها"}</span>
                        <span className="hashtag">
                            <span>{(lang === "en")? "#AlarganLife" :"#حياةالارجان"}</span>
                            <br/> 
                            {/* <Link to={langUrl + "/media/lifestyle"}>{(lang === "en")? "Explore" :"أكتشف"}</Link> */}
                            <a>{(lang === "en")? "Explore" :"أكتشف"}</a>
                        </span>
                    </div>
                    <div className="alargan-life-images">
                        <img src={alargan_life[0].media_url} alt="alargan life"/>
                        <img src={alargan_life[1].media_url} alt="alargan life"/>
                    </div>
                </div>
            </section>
        );
    }

    renderNewsSection = (data, lang) => {
        const {news} = data;
        
        return (
            <section className="news-section">
                <h1>{(lang === "en")? "News" :"أخبار"}</h1>
                {news.map((details, i)=>{
                    return (
                        <NewsCard 
                            key={'newsDetails-' + i}
                            info={details["description_" + lang]}
                            meta={details.published_at}
                            image={details.image_url}
                            title={details["name_" + lang]}
                            link={details.news_link}
                        />
                    )
                })}
            </section>
        );
    }

    render (){
        const {data, lang, langUrl} = this.state;
        return (
            <>
            {(data)? <> 
                <section className="home-header no-padding" style={{backgroundImage:"url("+data.hero.image[0].media_url+")"}}>
                    <div className="home-header-text">
                        <h5>{data.hero.data[0]["value_" + lang]}</h5>
                        <strong>{data.hero.data[1]["value_" + lang]}</strong>
                        <p>{data.hero.data[2]["value_" + lang]}</p>
                        <Link to={langUrl + "/properties/" + data.hero.data[3].value_ar + "/" + slugify(data.hero.data[1]["value_en"])}>{(lang === "ar")? "اكتشف المزيد":"Find Out More"}</Link>
                    </div>
                </section>
                <DescriptionCard 
                    image={data.social_responsibility.image[0].media_url}
                    title={data.social_responsibility.data[0]["value_" + lang]}
                    info={data.social_responsibility.data[1]["value_" + lang]}
                    link={"/firm/social"}
                />
                {this.renderCategorySection(data, lang)}
                <DescriptionCard 
                    image={data.project.cover[0].image_url}
                    title={data.project["name_" + lang]}
                    info={data.project["featured_description_" + lang]}
                    link={"/properties/" + data.project.id + "/" + slugify(data.project["name_en"])}
                />
                {this.renderAlarganLife(data, lang, langUrl)}
                {this.renderNewsSection(data, lang)}
                </>
                :<Loader className="loader" type="TailSpin" color="#80008D" height={100} width={100} /> }
            </>
        );
    }
}

export default withRouter(props => <Home {...props}/>);