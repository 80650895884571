import React from 'react';
import {Link} from "react-router-dom";


class Accordion extends React.Component {

    render () {
        return (
            <div className='wrapper'>
                <ul className='accordion-list'>
                    {this.props.data.map((data, key) => {
                        return (
                            <li className='accordion-list__item' key={"AccordionItem-"+ key}>
                                <AccordionItem {...data}/>
                            </li>
                        )
                    })}
                </ul>
            </div>
        )
    }
}

class AccordionItem extends React.Component {
  state = {
    opened: false
  }
  
  render () {
    const {
      props: {
        paragraph,
        title,
        link
      },
      state: {
        opened
      }
    } = this;
    
    return (
      <div
        {...{
          className: `accordion-item, ${opened && 'accordion-item--opened'}`,
          onClick: () => { this.setState({ opened: !opened }) }
        }}
      >
        <div {...{ className: 'accordion-item__line' }}>
          <h3 {...{ className: 'accordion-item__title' }}>
            {title}
          </h3>
          <span {...{ className: 'accordion-item__icon' }}/>
        </div>
          <div {...{ className: 'accordion-item__inner' }}>
            <div {...{ className: 'accordion-item__content' }}>
              <p {...{ className: 'accordion-item__paragraph' }}>
                {paragraph}
              </p>
              {(link)? <Link {...{ className: 'accordion-item__link' }} to={link.href}> {link.title} </Link> : ''}
            </div>
          </div>
      </div>
    )
  }
}

export default Accordion;