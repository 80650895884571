import React from 'react';
import {withRouter} from "react-router-dom";
import Api from './../../api/api';
import Loader from "react-loader-spinner";
import DescriptionCard from './../descriptionCard';
import slugify from 'react-slugify';

class Sustainability extends React.Component{

    state = {
        data: null,
        lang:"",
    }

    whatPage = () => {
        const {pathname} = this.props.location;
        return pathname;
    }

    setLang = (whatPage) => {
        let lang = 'en';
        if (whatPage.match(/^\/ar/gm)) { 
            lang = 'ar';
            document.title ="الاستدامة / الارجان / تطوير عقاري";
        }
        else{
            document.title ="Sustainability / Alargan / Real Estate Development";
        }

        this.setState({lang: lang});
    }

    componentDidMount(){
        const whatPage = this.whatPage();
        this.setLang(whatPage);
        
        Api.getAllSustainability().then((response)=>{
            this.setState({data: response.data})
        });
        
    }

    createMarkup = (data) => {
        return {__html: data};
    }

    render (){
        const {data, lang} = this.state;
        return (
            <div className="sustainability-page">
                {(data)? 
                    <>
                        <div className="sustainability-page-text">
                            <h2 className="bold" dangerouslySetInnerHTML={this.createMarkup(data.data[0]["value_" + lang])}/>
                            {/* <span>{data.data[1]["value_" + lang]}</span> */}
                        </div>
                        <div className = ""></div>
                        {data.project.map((project, i)=>{
                            return (
                                <DescriptionCard 
                                    image={project.cover[0].image_url}
                                    title={project["name_" + lang]}
                                    info={project["featured_description_" + lang]}
                                    link={"/properties/" + project.id + "/" + slugify(project["name_en"])}
                                />
                            )
                        })}
                    </>
                :<Loader className="loader" type="TailSpin" color="#80008D" height={100} width={100} />}
            </div>
        );
    }
}

export default withRouter(props => <Sustainability {...props}/>);