import React from 'react';
import {withRouter, Link} from "react-router-dom";

import {PageSection, Left,  Right} from './../components/pageSection/pageSection';
import Overview from './../components/governance/overview';
import Framework from './../components/governance/framework';
import Policies from './../components/governance/policies';
import Complaints from './../components/governance/complaints';

class Governance extends React.Component{

    state = {
        lang: ''
    }

    componentDidMount(){
        this.setLang(this.whatPage());
    }

    setLang = (isPage) => {
        let lang = '';

        if (isPage.match(/^\/ar/gm)) { 
            lang = 'ar';
        }
        
        if(lang) {
            this.setState({lang: '/' + lang});
        }else{
            this.setState({lang: ''});
        }
    }

    whatPage = () => {
        const {path} =  this.props.match;
        return path;
    }

    renderLinks = () => {
        const {lang} = this.state;
        const page = this.whatPage();
        switch(page) {
            default:
            case "/ar/governance":
            case "/governance":
                return(
                    <div className="link-section">
                        <strong>{lang? "نظرة عامة" : "OVERVIEW"}</strong>
                        <Link to={lang +"/governance/framework"}>{lang? "إطار العمل" : "FRAMEWORK"}</Link>
                        <Link to={lang +"/governance/policies"}>{lang? "سياسات" : "POLICIES"}</Link>
                        <Link to={lang +"/governance/complaints"}>{lang? "شكاوى العملاء" : "CLIENT COMPLAINTS"}</Link>
                    </div>
                );
            case "/ar/governance/framework":
            case "/governance/framework":
                return(
                    <div className="link-section">
                        <Link to={lang + "/governance"}>{lang? "نظرة عامة" : "OVERVIEW"}</Link>
                        <strong>{lang? "إطار العمل" : "FRAMEWORK"}</strong>
                        <Link to={lang +"/governance/policies"}>{lang? "سياسات" : "POLICIES"}</Link>
                        <Link to={lang +"/governance/complaints"}>{lang? "شكاوى العملاء" : "CLIENT COMPLAINTS"}</Link>
                    </div>
                );
            case "/ar/governance/policies":
            case "/governance/policies":
                return(
                    <div className="link-section">
                        <Link to={lang + "/governance"}>{lang? "نظرة عامة" : "OVERVIEW"}</Link>
                        <Link to={lang +"/governance/framework"}>{lang? "إطار العمل" : "FRAMEWORK"}</Link>
                        <strong>{lang? "سياسات" : "POLICIES"}</strong>
                        <Link to={lang +"/governance/complaints"}>{lang? "شكاوى العملاء" : "CLIENT COMPLAINTS"}</Link>
                    </div>
                );
            case "/ar/governance/complaints":
            case "/governance/complaints":
                return(
                    <div className="link-section">
                        <Link to={lang + "/governance"}>{lang? "نظرة عامة" : "OVERVIEW"}</Link>
                        <Link to={lang +"/governance/framework"}>{lang? "إطار العمل" : "FRAMEWORK"}</Link>
                        <Link to={lang +"/governance/policies"}>{lang? "سياسات" : "POLICIES"}</Link>
                        <strong>{lang? "شكاوى العملاء" : "CLIENT COMPLAINTS"}</strong>
                    </div>
                );   
        }            
    }

    renderContent = () => {
        const page = this.whatPage();
        switch(page) {
            default:
            case "/ar/governance":
            case "/governance":
                return <Overview />;
            case "/ar/governance/framework":
            case "/governance/framework":
                return <Framework />;
            case "/ar/governance/policies":
            case "/governance/policies":
                return <Policies />;
            case "/ar/governance/complaints":
            case "/governance/complaints":
                return <Complaints />;
        }
    }

    getClassContentName = () => {
        const page = this.whatPage();
        switch(page) {
            default:
            case "/ar/governance":
            case "/governance":
                return "overview-page";
            case "/ar/governance/framework":
            case "/governance/framework":
                return "governance-framework";
            case "/ar/governance/policies":
            case "/governance/policies":
                return "governance-policies";
            case "/ar/governance/complaints":
            case "/governance/complaints":
                return "governance-complaints";
        }

    }

    render (){
        const {lang} = this.state;
        return (
            <div className="governance-page">
                <PageSection>   
                        <Left>
                            <strong>{lang? "حوكمة الشركات" : "CORPORATE GOVERNANCE"}</strong>
                            {this.renderLinks()}
                        </Left>
                        <Right className={this.getClassContentName()}>
                            {this.renderContent()}
                        </Right>
                </PageSection>
            </div>
        );
    }
}

export default withRouter(props => <Governance {...props}/>);
