import React from 'react';
import ArticleComponent from './../article-component';
import {withRouter} from "react-router-dom";
import Loader from "react-loader-spinner";
import Api from './../../api/api';

class Documents extends React.Component{

    state = {
        data: null,
        lang:"",
    }

    whatPage = () => {
        const {pathname} = this.props.location;
        return pathname;
    }

    setLang = (whatPage) => {
        let lang = 'en';
        if (whatPage.match(/^\/ar/gm)) { 
            lang = 'ar';
            document.title ="وثائق الجمعية العمومية / الارجان / تطوير عقاري";
        }
        else{
            document.title ="AGM DOCUMENTS / Alargan / Real Estate Development";
        }

        this.setState({lang: lang});
    }

    componentDidMount(){
        const whatPage = this.whatPage();
        this.setLang(whatPage);
        
        Api.getAllAgmDocuments().then((response)=>{
            this.setState({data: response.data.agm_documents})
        });
        
    }

    render (){
        const {data, lang} = this.state;

        return (
            <div className="agm-documents-sections">
                {(data)? 
                    <div className="agm-section article-component-section-listing">
                    {data.map((item,i)=>{
                        const className = (i === 0)? "first-item": "";
                        return (
                            <ArticleComponent
                                key={item["document" + lang] + "-" + i}
                                title={item["name_" + lang]}
                                link={item.download_link}
                                linkIcon={(lang === "ar")? "تحميل":"Download"}
                                className={className}
                            />
                        )
                    })}
                    </div>
                :<Loader className="loader" type="TailSpin" color="#80008D" height={100} width={100} />}
            </div>
        );
    }
}

export default withRouter(props => <Documents {...props}/>);
