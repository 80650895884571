import React from 'react';
import {withRouter} from "react-router-dom";
import Api from './../../../api/api';
import Loader from "react-loader-spinner";
import MajorShareholdersTable from "./majorShareholdersTable";
import EventTable from "./eventTable";

class Shareholder extends React.Component{

    state = {
        data: null,
        lang:"",
    }

    whatPage = () => {
        const {pathname} = this.props.location;
        return pathname;
    }

    setLang = (whatPage) => {
        let lang = 'en';
        if (whatPage.match(/^\/ar/gm)) { 
            lang = 'ar';
            document.title ="المساهمون وتوزيع الأرباح / الارجان / تطوير عقاري";
        }
        else{
            document.title ="SHAREHOLDERS & DIVIDENDS / Alargan / Real Estate Development";
        }

        this.setState({lang: lang});
    }

    componentDidMount(){
        const whatPage = this.whatPage();
        this.setLang(whatPage);
        
        Api.getAllShareholdersDividends().then((response)=>{
            this.setState({data: response.data})
        });
        
    }

    getShareHolders = (data) => {
        return data.shareholders;
    }
    getDividends = (data) => {
        return data.dividends;
    }

    render (){
        const {data, lang} = this.state;
        return (
            <div>
                {(data)? 
                    <>
                        <div className="shareholders">
                            <h1>{(lang === "ar")? "المساهمين":"SHAREHOLDERS"}</h1>
                            <MajorShareholdersTable data={ this.getShareHolders(data)} lang={lang}/>
                        </div>
                        <div className="dividends">
                            <h1>{(lang === "ar")? "توزيع الأرباح":"DIVIDENDS"}</h1>
                            <EventTable data={ this.getDividends(data)} lang={lang}/>
                        </div>
                    </>
                : <Loader className="loader" type="TailSpin" color="#80008D" height={100} width={100} />}
            </div>
        );
    }
}

export default withRouter(props => <Shareholder {...props}/>);