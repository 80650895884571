import React from 'react';
import { withRouter, Link } from 'react-router-dom';

import ContactCard from './contactCard';

import logo from '../assets/img/logo.svg';
import fbLogo from '../assets/img/fb-icon.svg';
import igLogo from '../assets/img/ig-icon.svg';
import linkedinLogo from '../assets/img/linkedin-icon.svg';

class Footer extends React.Component {
  state = {
    lang: '',
  };

  componentDidMount() {
    this.setLang(this.whatPage());
  }

  setLang = (isPage) => {
    let lang = '';

    if (isPage.match(/^\/ar/gm)) {
      lang = 'ar';
    }

    if (lang) {
      this.setState({ lang: '/' + lang });
    } else {
      this.setState({ lang: '' });
    }
  };

  whatPage = () => {
    const { pathname } = this.props.location;
    return pathname;
  };

  extraDesktopFooterContent = () => {
    const { lang } = this.state;
    const langAbbr = lang.split('/').join('');
    return (
      <div className='footer-d-content'>
        <div className='footer-logo'>
          <Link to={'/' + langAbbr}>
            <img src={logo} alt='alargan logo' />
          </Link>
        </div>
        <div className='footer-nav-section'>
          <div className='footer-nav-section-1'>
            <div className='footer-nav-content'>
              <strong className='footer-nav-content-title'>
                {lang ? 'اتصل بنا' : 'CONTACT US'}
              </strong>
              <a href='mailto:info@alargan.com'>info@alargan.com</a>
              <a href='tel:+965 222 63222' class='footer-phone'>
                {lang ? ' +٦٣٢٢٢  ٢٢٢  ٩٦٥ ' : '+965 222 63222'}
              </a>
            </div>
            <div className='footer-nav-content'>
              <strong className='footer-nav-content-title'>
                {lang ? 'وظائف' : 'CAREERS'}
              </strong>
              <Link to={lang + '/careers'}>
                {lang ? 'إنضم إلى عائلتنا' : 'WORK WITH US'}
              </Link>
            </div>
            <div className='footer-nav-content'>
              <strong className='footer-nav-content-title'>
                {lang ? 'وسائل الاعلام والمدونة' : 'MEDIA & BLOG'}
              </strong>
              {/* <Link to={lang + "/media"}>{lang? "أحدث الأخبار والأرشيفات" : "MEDIA CENTER"}</Link>
                            <Link to={lang + "/media/lifestyle"}>{lang? "المركز الاعلامي المدونة" : "LIFESTYLE BLOG"}</Link> */}
              <a>{lang ? 'أحدث الأخبار والأرشيفات' : 'MEDIA CENTER'}</a>
              <a>{lang ? 'المركز الاعلامي المدونة' : 'LIFESTYLE BLOG'}</a>
            </div>
          </div>
          <div className='footer-nav-section-2'>
            <div className='footer-nav-content'>
              <strong className='footer-nav-content-title'>
                {lang === '' ? 'ABOUT US' : 'من نحن'}
              </strong>
              <strong className='footer-nav-content-subtitle'>
                {lang ? 'الشركة' : 'THE FIRM'}
              </strong>
              <Link to={lang + '/firm'}>
                {lang ? 'نبذة عن الشركة' : 'PROFILE'}
              </Link>
              <Link to={lang + '/firm/history'}>
                {lang ? 'نبذة تاريخية' : 'HISTORY'}
              </Link>
              <Link to={lang + '/firm/sustainability'}>
                {lang ? 'الاستدامة' : 'SUSTAINABILITY'}
              </Link>
              <Link to={lang + '/firm/social'}>
                {lang ? 'المسئولية الإجتماعية' : 'SOCIAL RESPONSIBILITY'}
              </Link>
              <Link to={lang + '/firm/awards'}>
                {lang ? 'الجوائز والشهادات التقديرية' : 'AWARDS & RECOGNITIONS'}
              </Link>
              <Link to={lang + '/firm/leadership'}>
                {lang ? 'الإدارة' : 'LEADERSHIP'}
              </Link>
            </div>
            <div className='footer-nav-content'>
              <strong className='footer-nav-content-subtitle'>
                {lang ? 'حوكمة الشركات' : 'COPRORATE GOVERNANCE'}
              </strong>
              <Link to={lang + '/governance'}>
                {lang ? 'نظرة عامة' : 'OVERVIEW'}
              </Link>
              <Link to={lang + '/governance/framework'}>
                {lang ? 'إطار العمل' : 'FRAMEWORK'}
              </Link>
              <Link to={lang + '/governance/policies'}>
                {lang ? 'سياسات' : 'POLICIES'}
              </Link>
              <Link to={lang + '/governance/complaints'}>
                {lang ? 'شكاوى العملاء' : 'CLIENTS COMPLAINTS'}
              </Link>
            </div>
          </div>
          <div className='footer-nav-section-3'>
            <div className='footer-nav-content'>
              <strong className='footer-nav-content-subtitle'>
                {lang ? 'الخبرة' : 'EXPERTISE'}
              </strong>
              <Link to={lang + '/expertise'}>
                {lang ? 'نظرة عامة' : 'OVERVIEW'}
              </Link>
              <Link to={lang + '/expertise/services'}>
                {lang ? 'الخدمات' : 'SERVICES'}
              </Link>
              <Link to={lang + '/expertise/sectors'}>
                {lang ? 'القطاعات' : 'SECTORS'}
              </Link>
              <Link to={lang + '/expertise/locations'}>
                {lang ? 'المواقع' : 'LOCATIONS'}
              </Link>
            </div>
            <div className='footer-nav-content'>
              <strong className='footer-nav-content-subtitle'>
                {lang ? 'علاقات المستثمرين' : 'INVESTOR RELATIONS'}
              </strong>
              <Link to={lang + '/investor'}>
                {lang ? 'نظرة عامة' : 'OVERVIEW'}
              </Link>
              <Link to={lang + '/investor/annual-reports'}>
                {lang ? 'التقارير السنوية' : 'ANNUAL REPORTS'}
              </Link>
              <Link to={lang + '/investor/disclosures'}>
                {lang ? 'إفصاحات' : 'DISCLOSURES'}
              </Link>
              <Link to={lang + '/investor/credit-rating'}>
                {lang ? 'التصنيف الائتماني' : 'CREDIT RATING'}
              </Link>
              <Link to={lang + '/investor/agm-documents'}>
                {lang ? 'وثائق الجمعية العمومية' : 'AGM DOCUMENTS'}
              </Link>
              <Link to={lang + '/investor/shareholders'}>
                {lang ? 'المساهمون وتوزيع الأرباح' : 'SHAREHOLDERS & DIVIDENDS'}
              </Link>
              <Link to={lang + '/investor/analyst'}>
                {lang ? 'تقارير المحللين' : 'ANALYST COVERAGE'}
              </Link>
              {/* <Link to={lang +"/investor/calendar"}>{lang? "التقويم المالي" : "FINANCIAL CALENDAR"}</Link> */}
              <Link to={lang + '/investor/candidate-application'}>
                {lang ? 'طلب ترشح' : 'CANDIDATE APPLICATION FORM'}
              </Link>
            </div>
          </div>
          <div className='footer-nav-section-4'>
            <div className='footer-nav-content'>
              <strong className='footer-nav-content-title'>
                {lang === '' ? 'PROJECTS' : 'المشاريع'}
              </strong>
              <strong className='footer-nav-content-subtitle'>
                {lang ? 'حسب الموقع' : 'BY LOCATION'}
              </strong>
              <Link
                to={{
                  pathname: lang + '/properties',
                  search: '?location=' + (lang ? 'الكويت' : 'kuwait'),
                  state: {
                    filters: { location: lang ? 'الكويت' : 'kuwait', type: '' },
                    reset: true,
                  },
                }}
              >
                {lang ? 'الكويت' : 'KUWAIT'}
              </Link>
              <Link
                to={{
                  pathname: lang + '/properties',
                  search: '?location=' + (lang ? 'عمان' : 'oman'),
                  state: {
                    filters: { location: lang ? 'عمان' : 'oman', type: '' },
                    reset: true,
                  },
                }}
              >
                {lang ? 'عمان' : 'OMAN'}
              </Link>
              <Link
                to={{
                  pathname: lang + '/properties',
                  search: '?location=' + (lang ? 'المغرب' : 'morocco'),
                  state: {
                    filters: {
                      location: lang ? 'المغرب' : 'morocco',
                      type: '',
                    },
                    reset: true,
                  },
                }}
              >
                {lang ? 'المغرب' : 'MOROCCO'}
              </Link>
              <Link
                to={{
                  pathname: lang + '/properties',
                  search: '?location=' + (lang ? 'البحرين' : 'bahrain'),
                  state: {
                    filters: {
                      location: lang ? 'البحرين' : 'bahrain',
                      type: '',
                    },
                    reset: true,
                  },
                }}
              >
                {lang ? 'البحرين' : 'BAHRAIN'}
              </Link>
            </div>
            <div className='footer-nav-content'>
              <strong className='footer-nav-content-subtitle'>
                {lang ? 'حسب النوع' : 'BY TYPE'}
              </strong>
              <Link
                to={{
                  pathname: lang + '/properties',
                  search: '?type=' + (lang ? 'سكني' : 'residential'),
                  state: {
                    filters: {
                      location: '',
                      type: lang ? 'سكني' : 'residential',
                    },
                    reset: true,
                  },
                }}
              >
                {lang ? 'سكني' : 'RESIDENTIAL'}
              </Link>
              <Link
                to={{
                  pathname: lang + '/properties',
                  search: '?type=' + (lang ? 'تعليمي' : 'education'),
                  state: {
                    filters: {
                      location: '',
                      type: lang ? 'تعليمي' : 'education',
                    },
                    reset: true,
                  },
                }}
              >
                {lang ? 'تعليمي' : 'EDUCATION'}
              </Link>
              <Link
                to={{
                  pathname: lang + '/properties',
                  search: '?type=' + (lang ? 'تجاري' : 'commercial'),
                  state: {
                    filters: {
                      location: '',
                      type: lang ? 'تجاري' : 'commercial',
                    },
                    reset: true,
                  },
                }}
              >
                {lang ? 'تجاري' : 'COMMERCIAL'}
              </Link>
              <Link
                to={{
                  pathname: lang + '/properties',
                  search: '?type=' + (lang ? 'الضيافة' : 'hospitality'),
                  state: {
                    filters: {
                      location: '',
                      type: lang ? 'الضيافة' : 'hospitality',
                    },
                    reset: true,
                  },
                }}
              >
                {lang ? 'الضيافة' : 'HOSPITALITY'}
              </Link>
              <Link
                to={{
                  pathname: lang + '/properties',
                  search: '?type=' + (lang ? 'الصحة' : 'health'),
                  state: {
                    filters: { location: '', type: lang ? 'الصحة' : 'health' },
                    reset: true,
                  },
                }}
              >
                {lang ? 'الصحة' : 'HEALTH'}
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <>
        <ContactCard />
        <footer className='footer'>
          {this.extraDesktopFooterContent()}
          <div className='footer-container'>
            <div className='footer-icons'>
              <a
                href='https://www.facebook.com/alarganint/'
                rel='noreferrer'
                target='_blank'
              >
                <img className='fb-logo-img' src={fbLogo} alt='fb link' />
              </a>
              <a
                href='https://www.instagram.com/alarganint/'
                rel='noreferrer'
                target='_blank'
              >
                <img className='ig-logo-img' src={igLogo} alt='ig link' />
              </a>
              <a
                href='https://www.linkedin.com/company/alarganint/'
                rel='noreferrer'
                target='_blank'
              >
                <img
                  className='linkedin-logo-img'
                  src={linkedinLogo}
                  alt='linkedin link'
                />
              </a>
            </div>
            <div className='footer-policies'>
              <Link to='/#'>TERMS OF USE</Link>
              <Link to='/#'>LEGAL</Link>
              <Link to='/#'>PRIVACY POLICY</Link>
            </div>
            <div className='footer-copy-rights'>
              <span>
                ©{new Date().getFullYear()} Alargan. All Rights Reserved.
              </span>
            </div>
          </div>
        </footer>
      </>
    );
  }
}

export default withRouter((props) => <Footer {...props} />);
