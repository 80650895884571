import React from 'react';


class ShowMore extends React.Component{
    state = {
        data: this.props.data || [],
        iterator: 0,
    }

    componentDidMount(){
        this.triggerIteratorOnChange();
    }

    updateState = (name, prevProps) => {
        if(this.props[name] !== prevProps[name]){
            this.setState({[name]: this.props[name]});
        }
    }

    componentDidUpdate(prevProps){
        this.updateState('data', prevProps);
    }


    showMoreData = () => {
        let {iterator, data} = this.state;
        let {chunkSize} = this.props;
        const chunkTotal = Math.ceil(data/chunkSize);
        if (iterator === chunkTotal-1 || chunkTotal.length <= 1) {
           this.setState({showMore: false});
          return false;
        }
        this.setState({iterator: iterator++});
        return true;
    }

    triggerIteratorOnChange = () => {
        const {iterator, data} = this.state;
        const {chunkSize, itemId} = this.props;

        const chunkedData = this.chunkArray(data, chunkSize);
        this.props.onChange(iterator, chunkedData, itemId);
    }

    onClickShowMore = () => {
        const {iterator, data} = this.state;
        const {chunkSize} = this.props;
        const chunkTotal = Math.ceil(data.length/chunkSize);
        if(iterator < chunkTotal){
            this.setState({iterator: iterator+1}, ()=>{
                this.triggerIteratorOnChange();
            });           
        }
    }

    renderShowMore = () => {
        const {iterator, data} = this.state;
        const {chunkSize} = this.props;
        const className = this.props.className? this.props.className : '';
        const chunkTotal = Math.ceil(data.length/chunkSize);

        return <>{(iterator === chunkTotal-1 || chunkTotal <= 1)? 
        <></>
        :  <a href="/#" className={`${className} cursor-pointer show-more-btn`} onClick={(e)=>{e.preventDefault();this.onClickShowMore()}}>{this.props.showMoreText || "Show More"}</a>
        
        }</>
    }

    
    chunkArray = (array, size) => {
        let result = []
        for (let value of array){
            let lastArray = result[result.length -1 ]
            if(!lastArray || lastArray.length === size){
                result.push([value])
            } else{
                lastArray.push(value)
            }
        }
        return result
    }

    render(){
        return this.renderShowMore();
    }
}

export default ShowMore;