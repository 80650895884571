import React from 'react';
import {withRouter, Link} from "react-router-dom";
import Api from './../../api/api';
import Loader from "react-loader-spinner";


import Accordion from './../accordion';

class Complaints extends React.Component{

    state = {
        data: null,
        lang:"",
    }

    whatPage = () => {
        const {pathname} = this.props.location;
        return pathname;
    }

    setLang = (whatPage) => {
        let lang = 'en';
        if (whatPage.match(/^\/ar/gm)) { 
            lang = 'ar';
            document.title ="شكاوى العملاء / الارجان / تطوير عقاري";
        }
        else{
            document.title ="Client Complaints / Alargan / Real Estate Development";
        }

        this.setState({lang: lang});
    }

    componentDidMount(){
        const whatPage = this.whatPage();
        this.setLang(whatPage);
        
        Api.getAllClientComplaints().then((response)=>{
            this.setState({data: response.data.data})
        });
        
    }

    createMarkup = (data) => {
        return {__html: data};
    }


    render (){
        const {data, lang} = this.state;
        return (
            <div className="complaints-page">
            {(data)? <p dangerouslySetInnerHTML={this.createMarkup(data[0]["value_" + lang])} /> : <Loader className="loader" type="TailSpin" color="#80008D" height={100} width={100} />}
            </div>
        );
    }
}

export default withRouter(props => <Complaints {...props}/>);