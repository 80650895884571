import React from "react";
import Sticky from 'react-sticky-el';


export const Left = (props) => {
    const size = window.innerWidth >= 1024;
    return (props.children)? <div className={` ${props.className || ''} left-section`}>{(size)?<Sticky boundaryElement=".left-section" style = {{position: 'relative'}} hideOnBoundaryHit={true} topOffset={-100}>{props.children}</Sticky> : props.children}</div> : ''
}

export const Right = (props) => {
    return (props.children)? <div className={`${props.className || ''} right-section`}>{props.children}</div> : ''
}

export const PageSection = (props) => {
    return <section className={`page-section`}>{props.children || ''}</section>;
}