import React from 'react';
import {withRouter} from "react-router-dom";
import {PageSection, Left, Right} from './../components/pageSection/pageSection.js';
import {TextField} from '@material-ui/core';
import Api from './../api/api.js';
import Properties from './../components/searchComponents/propertiesSearch.js';
import Blogs from './../components/searchComponents/blogsSearch.js';
import ShowMore from './../components/showMore';

class SearchPage extends React.Component{
    state = {
        lang: '',
        data:'',
        empty:null,
        renderedProperties: <></>
    }

    componentDidMount(){
        this.setLang(this.whatPage());
    }

    setLang = (isPage) => {
        let lang = '';

        if (isPage.match(/^\/ar/gm)) { 
            lang = 'ar';
            document.title ="ابحث / الارجان / تطوير عقاري";
        }
        else{
            document.title ="Search / Alargan / Real Estate Development";
        }
        this.setState({lang: lang});
    }

    whatPage = () => {
        const {pathname} =  this.props.location;
        return pathname;
    }

    websiteSearch = (query) => {
        if (query.length > 2) {
            Api.getSearchData(query).then((response)=>{
                this.setState({data: response.data})
            });
        }
        else{
            this.setState({data:'', empty:false});
        }
    }

    checkResults = (data) => {
        if (data.projects.length !== 0 || data.blogs.length !==0 ){
            return false;
        }
        else{
            return true;
        }
    }

    getPropertiesWithChunks = (iterator, chunkedData) =>{
        const {lang} = this.state;
        const langAbrr = (lang === 'ar')? 'ar': 'en';

        this.setState({
            renderedProperties: <> {
                chunkedData.map((chunkedItem, i)=>{
                  return <>{(i <= iterator)? 
                  <>{ 
                     chunkedItem.map((project,j)=> <Properties 
                       key={project.id + "-properties-id"}
                       detail={project["name_" + langAbrr]}
                       cover={project.cover}
                       city={project.city}
                       types={project.types}
                       link={lang + "/properties/" + project.id}
                       className={(j === 0 && i ===0)? "first-item": (j===(chunkedItem.length-1) && (i === iterator))? "last-item":""}
                       />
                     )
                  }</>
                  : <></>}</>
                })
             } </>
        });

    }

    render (){
        const {data, lang, renderedProperties} = this.state
        const langAbrr = (lang === 'ar')? 'ar': 'en';
        return (
            <div className="search-page ">
                <> 
                    <PageSection className="search-input-section">  
                        <TextField autoFocus="true" label={lang? "ابحث" : "Search"} onChange={(e)=>this.websiteSearch(e.target.value)}/>
                    </PageSection>
                    <div className="search-result-section">
                        {(data)? 
                            <>
                            {(this.checkResults(data))? <p className="no-results">No results found, please try another keyword.</p> :
                                <>
                                {(data.projects.length !==0)?
                                    <PageSection> 
                                        <Left>
                                            <p>{(lang==="ar")? 'المشاريع': "PROPERTIES"}</p>
                                        </Left>
                                        <Right>
                                            {renderedProperties}
                                            <ShowMore 
                                                data={data.projects}
                                                chunkSize={2}
                                                onChange={this.getPropertiesWithChunks}
                                                showMoreText = {(langAbrr === "ar")? "أظهر المزيد": "Show More"}
                                                className="article-component-section-link"

                                            />
                                        </Right>
                                    </PageSection>
                                :''}
                                {(data.blogs.length !==0)?
                                    <PageSection> 
                                        <Left>
                                            <p>{(lang==="ar")? 'المدونات': "BLOGS"}</p>
                                        </Left>
                                        <Right>
                                            {data.blogs.map((blog,i)=>{
                                                return <Blogs
                                                    title= {blog["title_" + langAbrr]}
                                                    link = {lang + "/media/lifestyle/" + blog.id}
                                                />
                                                
                                            })}
                                        </Right>
                                    </PageSection>
                                :''}
                                </>
                            }
                            </>
                         :''}
                    </div>
                </>
            </div>
        );
    }
}

export default withRouter(props => <SearchPage {...props}/>);