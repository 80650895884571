import React from 'react';
import {withRouter} from "react-router-dom";
import Api from './../../api/api';
import Loader from "react-loader-spinner";


import Accordion from './../accordion';

class Policies extends React.Component{

    state = {
        data: null,
        lang:"",
    }

    whatPage = () => {
        const {pathname} = this.props.location;
        return pathname;
    }

    setLang = (whatPage) => {
        let lang = 'en';
        if (whatPage.match(/^\/ar/gm)) { 
            lang = 'ar';
            document.title ="سياسات / الارجان / تطوير عقاري";
        }
        else{
            document.title ="Policies / Alargan / Real Estate Development";
        }

        this.setState({lang: lang});
    }

    componentDidMount(){
        const whatPage = this.whatPage();
        this.setLang(whatPage);
        
        Api.getAllPolicies().then((response)=>{
            this.setState({data: response.data})
        });
        
    }

    createMarkup = (data) => {
        return {__html: data};
    }

    renderAccordion = (response, lang) => {
        const {policies} = response
        const data = policies.map((item, i)=> {
           
            return{
                title:item["name_" + lang],
                paragraph: <div key={"policy-" + i} className="policies-details-container">
                    <p className="bold" dangerouslySetInnerHTML={this.createMarkup( item["description_" + lang])}/>
                </div>
            }
        })
        return <Accordion data={data}/>;
    }

    render (){
        const {data, lang} = this.state;
        return (
            <div className="policies-page">
            {(data)? this.renderAccordion(data, lang) : <Loader className="loader" type="TailSpin" color="#80008D" height={100} width={100} />}
                
            </div>
        );
    }
}

export default withRouter(props => <Policies {...props}/>);