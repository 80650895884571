import React from 'react';
import {withRouter, Link} from "react-router-dom";


import { slide as Menu } from 'react-burger-menu';
import logo from '../../assets/img/logo.svg';
import miniLogo from '../../assets/img/simple-logo.svg';
import fbLogo from '../../assets/img/fb-icon.svg';
import igLogo from '../../assets/img/ig-icon.svg';
import linkedinLogo from '../../assets/img/linkedin-icon.svg';

class MobileHeader extends React.Component {
    
    state = {
        lang: '',
        menuOpen: false
    }

    componentDidMount(){
        this.setLang(this.whatPage());
    }
    
    handleMenuStateChange (state) {
        this.setState({menuOpen: state.isOpen})  
    }

    setLang = (isPage) => {
        let lang = '';

        if (isPage.match(/^\/ar/gm)) { 
            lang = 'ar';
        }
        
        if(lang) {
            this.setState({lang: '/' + lang});
        }else{
            this.setState({lang: ''});
        }
    }

    closeMenu () {
        this.setState({menuOpen: false})
    }

    whatPage = () => {
        const {pathname} =  this.props.location;
        return pathname;
    }
    render (){
        const {isHomePage} = this.props;
        const {lang, menuOpen} = this.state;
        const langAbbr = lang.split("/").join("");
        const homeClass = isHomePage? "mob-menu-home" : "";
        return (
            <div className={"mob-menu-container " + homeClass}>
                <div className="mobile-logo">
                    <Link to={"/" + langAbbr}>
                    {(isHomePage)? 
                    <img src={logo} alt="mobile logo"/>
                    :
                    <img src={miniLogo} className="mini-logo-styles" alt="mobile logo"/>
                    }
                    </Link>
                </div>
                <div className="mobile-menu">

                    <Menu
                        isOpen={menuOpen}
                        onStateChange={(state) => this.handleMenuStateChange(state)}
                    
                        right
                        width={ '100%' }
                        disableAutoFocus
                    >
                        <div className="logo-item" >
                            <img src={logo} alt="mobile logo"/>
                        </div>
                            {(this.state.lang === '')?
                            <a className="menu-item arabic-menu" href="/ar">عربي</a> 
                            // <a className="menu-item arabic-menu">عربي</a> 
                            : 
                            <a className="menu-item english-menu" href="/">English</a> 
                            }

                            {/* {(this.state.lang === '')?
                            <Link className="menu-item arabic-menu" onClick={(e)=>{this.closeMenu(e);this.setLang("/ar")}} to="/ar">عربي</Link> 
                            : 
                            <Link className="menu-item english-menu" onClick={(e)=>{this.closeMenu(e);this.setLang("/")}} to="/">English</Link> 
                            } */}
                        <div className="menu-item">
                            {(lang === '' )? "ABOUT" : "من نحن"}
                            <div className="mob-menu-item-container">
                                <Link className="menu-item" to={lang + "/firm"} onClick={(e)=>{this.closeMenu(e)}} >{lang? "الشركة" : "THE FIRM"}</Link>
                                <Link className="menu-item" to={lang +"/expertise"} onClick={(e)=>{this.closeMenu(e)}} >{lang? "الخبرة" : "EXPERTISE"}</Link>
                                <Link className="menu-item" to={lang +"/investor"} onClick={(e)=>{this.closeMenu(e)}} >{lang? "علاقات المستثمرين" : "INVESTOR RELATIONS"}</Link>
                                <Link className="menu-item" to={lang +"/governance"} onClick={(e)=>{this.closeMenu(e)}} >{lang? "حوكمة الشركات" : "COPRORATE GOVERNANCE"}</Link>
                            </div>
                        </div>
                        
                        <Link  className="menu-item" onClick={(e)=>this.closeMenu(e)} 
                            to={{
                                pathname: lang + "/properties",
                                state: { filters: {location: "", type: ""}, reset: true }
                            }}
                        >
                            {(lang === '' )? "PROPERTIES" : "المشاريع"}
                        </Link>
                        <Link className="menu-item" to={lang +"/contact-us"} onClick={(e)=>{this.closeMenu(e)}}>{lang? "اتصل بنا" : "CONTACT US"}</Link>
                        <Link className="menu-item" to={lang +"/careers"} onClick={(e)=>{this.closeMenu(e)}}>{lang? "وظائف" : "CAREERS"}</Link>
                        <Link className="menu-item" to="/media" onClick={(e)=>{this.closeMenu(e)}}>{lang? "وسائل الاعلام والمدونة" : "MEDIA & BLOG"}</Link>
                        <div className="mobile-bottom-section">
                            <div className="mobile-search">
                                <Link to={lang + "/search"} onClick={(e)=>{this.closeMenu(e)}} >{lang? "ابحث" : "SEARCH"}</Link>
                            </div>
                            <div className="mobile-socialmmedia">
                                <a href="https://www.facebook.com/alarganint/"  rel="noreferrer"  target="_blank"><img className="fb-logo-img" src={fbLogo} alt="fb link"/></a>
                                <a href="https://www.instagram.com/alarganint/"  rel="noreferrer" target="_blank"><img className="ig-logo-img" src={igLogo} alt="ig link"/></a>
                                <a href="https://www.linkedin.com/company/alarganint/" rel="noreferrer"  target="_blank"><img className="linkedin-logo-img" src={linkedinLogo} alt="linkedin link"/></a>
                            </div>
                        </div>
                    </Menu>    
                </div>
            </div>      
        );
    }
}

export default withRouter(props => <MobileHeader {...props}/>);