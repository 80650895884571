import React from 'react';
import {withRouter,Link} from "react-router-dom";

import rightArr from './../../assets/img/Group-550.svg';
import leftArrow from './../../assets/img/left-arrow.svg';


class MemberCard extends React.Component{
    state = {
        data: null,
        lang:"",
        langUrl:''

    }

    whatPage = () => {
        const {pathname} = this.props.location;
        return pathname;
    }

    setLang = (whatPage) => {
        let lang = 'en';
        if (whatPage.match(/^\/ar/gm)) { 
            lang = 'ar';
        }
        this.setState({lang: lang, langUrl:(lang==="en")? '' : '/ar'});
    }
    componentDidMount(){
        const whatPage = this.whatPage();
        this.setLang(whatPage);
    }

    renderMembers = () =>{
       const {data} = this.props;

        return(
            <>
                {data.map((member,i) => {
                    if(i%2 === 0){
                        return (
                            <div className="member-details-wrapper">
                               {this.renderMemberBlock(member)}
                               {(data[i+1])? this.renderMemberBlock(data[i+1]): ""}
                            </div>
                        )
                    }
                    return "";
                })} 
            </>      
        );
    }

    renderMemberBlock = ({title, name, link, boardTitle}) =>{
       const {lang, langUrl} = this.state;
        return (
            <>
            {(boardTitle)? <h2 className="board-title">{boardTitle}</h2> : ''}
            <div className="member-details">
                <div>
                    <p>{name}</p>
                    <p className="italic">{title}</p>
                </div>
                <div>
                    {(link)? <Link to={  langUrl+link}><img src={(lang ==="ar" )? leftArrow:rightArr} alt=""/></Link> : ''}
                </div>
            </div>
            </>
        );
    }

    render (){
        return this.renderMembers();
    }
}

export default withRouter(props => <MemberCard {...props}/>);
