import React from 'react';
import {withRouter, Link} from "react-router-dom";
import Api from './../../api/api';
import Loader from "react-loader-spinner";
import ArticleComponent from './../article-component';
import ShowMore from './../../components/showMore';

class Disclosures extends React.Component{


    state = {
        data: null,
        lang:"",
    }

    whatPage = () => {
        const {pathname} = this.props.location;
        return pathname;
    }

    setLang = (whatPage) => {
        let lang = 'en';
        if (whatPage.match(/^\/ar/gm)) { 
            lang = 'ar';
            document.title ="إفصاحات / الارجان / تطوير عقاري";
        }
        else{
            document.title ="DISCLOSURES / Alargan / Real Estate Development";
        }

        this.setState({lang: lang});
    }

    componentDidMount(){
        const whatPage = this.whatPage();
        this.setLang(whatPage);
        
        Api.getAllDisclosureYears().then((response)=>{
            this.setState({data: response.data.disclosure_years})
        });
        
    }

    getDataWithChunks = (iterator, chunkedData, itemId) =>{
        const {lang} = this.state;
        this.setState({
            ["renderedDisclosures" + itemId]: <> {
                chunkedData.map((chunkedItem, i)=>{
                  return <>{(i <= iterator)? 
                  <>{ 
                     chunkedItem.map((item, j)=> <ArticleComponent
                            key={item["disclosure_title_" + lang] + "-" + i}
                            title={item["disclosure_title_" + lang]}
                            link={item.download_link}
                            linkIcon={(lang === "ar")? "تحميل":"Download"}
                            className={(j === 0 && i ===0)? "first-item": ""}
                        />
                     )
                  }</>
                  : <></>}</>
                })
             } </>
        });

    }


    renderSection = (data, lang, index) => {
        const renderedDisclosures = this.state['renderedDisclosures'+index] || <></>;
        return (
            <div key={"key-financial-section-" + data["name_" + lang] + data.id} className="disclosure-section article-component-section-listing">
                <h2 className="bold">{data.disclosure_year}</h2>
                {renderedDisclosures}
                <ShowMore 
                    data={data.disclosure_titles}
                    chunkSize={4}
                    itemId={index}
                    onChange={this.getDataWithChunks}
                    showMoreText = {(lang === "ar")? "أظهر المزيد": "Show More"}
                    className="article-component-section-link"

                />
            </div>
        );
    }

    render (){
        const {data, lang} = this.state;
        return (
            <div className="disclosure-sections">
                {(data)? 
                    <>
                        {data.map((disclosureYear, index)=>{
                            return this.renderSection(disclosureYear, lang, index)
                        })}
                    </>
                : <Loader className="loader" type="TailSpin" color="#80008D" height={100} width={100} /> }
            </div>
        );
    }
}

export default withRouter(props => <Disclosures {...props}/>);
