import React from 'react';
import {withRouter} from "react-router-dom";
import Api from './../../api/api';
import Loader from "react-loader-spinner";

class History extends React.Component{

    state = {
        data: null,
        lang:"",
    }

    whatPage = () => {
        const {pathname} = this.props.location;
        return pathname;
    }

    setLang = (whatPage) => {
        let lang = 'en';
        if (whatPage.match(/^\/ar/gm)) { 
            lang = 'ar';
            document.title ="نبذة تاريخية / الارجان / تطوير عقاري";
        }
        else{
            document.title ="History / Alargan / Real Estate Development";
        }

        this.setState({lang: lang});
    }

    componentDidMount(){
        const whatPage = this.whatPage();
        this.setLang(whatPage);
        
        Api.getAllHistory().then((response)=>{
            this.setState({data: response.data})
        });
        
    }

    createMarkup = (data) => {
        return {__html: data};
    }

    render (){
        const {data, lang} = this.state;
        console.log(data)
        return (
            <div className="history-page">
                {(data)? 
                    <>
                        <div dangerouslySetInnerHTML={this.createMarkup(data.data["value_" + lang])}/>
                        {(lang ==="ar")? <img src={data.hero[0].media_url} alt=""/> : (<img src={data.hero[1].media_url} alt=""/>)}
                        
                    </>
                :<Loader className="loader" type="TailSpin" color="#80008D" height={100} width={100} />}
            </div>
        );
    }
}

export default withRouter(props => <History {...props}/>);
