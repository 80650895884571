import React from 'react';
import {withRouter} from "react-router-dom";
import Api from './../../api/api';
import Loader from "react-loader-spinner";


class Profile extends React.Component{

    state = {
        data: null,
        lang:"",
    }

    whatPage = () => {
        const {pathname} = this.props.location;
        return pathname;
    }

    setLang = (whatPage) => {
        let lang = 'en';
        if (whatPage.match(/^\/ar/gm)) { 
            lang = 'ar';
            document.title ="نبذة عن الشركة / الارجان / تطوير عقاري";
        }
        else{
            document.title ="Profile / Alargan / Real Estate Development";
        }
        this.setState({lang: lang});
    }

    componentDidMount(){
        const whatPage = this.whatPage();
        this.setLang(whatPage);
        
        Api.getAllProfileData().then((response)=>{
            this.setState({data: response.data})
        });
        
    }

    renderNetworth = (data, lang) => {
        const {profile_budgets} = data;
        return(
            <div className="networth" >
            {profile_budgets.map((net,i)=>{
                return (
                    <div className="networth-wrapper">
                    <h2 key={"networth-" + i} className="bold">{net["value_" + lang]}</h2>
                    <span>{net["name_" + lang]}</span>
                    </div>
                );
            })}
            </div>
        );
    }

    renderMissionVision = (data, lang) =>{
        const {profile_data} = data;
        const missions = [
            {
                title_en:"MISSION",
                title_ar:"المهمة",
                desc: profile_data[1]["value_" + lang]
            },
            {
                title_en:"VISION",
                title_ar:"الرؤية",
                desc: profile_data[2]["value_" + lang]
            },
        ]

        return(
            <div className="mission-vision">
                {missions.map((mission,i)=>{
                    const { desc}= mission;
                    return(
                        <div className={mission["title_en"]}>
                        <h1 key={"title-" + i}>{mission["title_" + lang]}</h1>
                        <h2>{desc}</h2>
                        </div>
                    );
                })}
            </div>
        );
    }

    createMarkup = (data) => {
        return {__html: data};
    }

    render (){
        const {data, lang} = this.state;
        return (
            <>
                {(data)? 
                    <>
                        <img src= {data.media_data[0].media_url} alt="profile head img" />
                        <div className="profile">
                        <div dangerouslySetInnerHTML={this.createMarkup(data.profile_data[0]["value_" + lang])}/>
                        </div>
                        {this.renderNetworth(data, lang)}
                        {this.renderMissionVision(data, lang)}
                        <div className="ceo-saying">
                            <span className="ceo-saying-text">{data.profile_data[3]["value_" + lang]}</span><br/>
                            <div className="ceo-details">
                                <span className="ceo-name">{data.profile_data[4]["value_" + lang]}</span><br/>
                                <span className="ceo-title">{data.profile_data[5]["value_" + lang]}</span>
                            </div>
                        </div>
                    </>
                :<Loader className="loader" type="TailSpin" color="#80008D" height={100} width={100} />}
            </>
        );
    }
}

export default withRouter(props => <Profile {...props}/>);
