import React from 'react';
import {withRouter, Link} from "react-router-dom";
import {PageSection, Left,  Right} from '../components/pageSection/pageSection';
import Loader from "react-loader-spinner";
import slugify from 'react-slugify';

import LazyLoad  from 'react-lazyload';
import {TextField} from '@material-ui/core';
import Fade from '@material-ui/core/Fade';
import Api from './../api/api'

import Filter from './../components/properties/filter';

// import Api from './../api/api';

class Properties extends React.Component{

    state = {
        filters: {
            location: "",
            type: "",
        },
        searchField:"",
        data: null,
        lang:""
    }

    whatPage = () => {
        const {pathname} = this.props.location;
        return pathname;
    }

    setLang = (whatPage) => {
        let lang = 'en';
        if (whatPage.match(/^\/ar/gm)) { 
            lang = 'ar';
            document.title ="المشاريع / الارجان / تطوير عقاري";
        }
        else{
            document.title ="Properties / Alargan / Real Estate Development";
        }

        this.setState({lang: lang});
    }

    componentDidMount(){
        const whatPage = this.whatPage();
        this.setLang(whatPage);
        Api.getProjects().then((response)=>{
            this.setState({data: response.data.project})
        });
        
    }

    renderContent = (filters, searchField) => {
        const {data, lang}= this.state;
        
        let filteredProps = data.filter(data => {
            const {city, types} = data;
            const name = data["name_" + lang];
            const location = city["name_" + lang] + " / " + city.country["name_" +lang];
            const type = types.map((item)=> item["name_" +lang].toLocaleLowerCase());

            if (searchField){
                const searched = searchField.toLocaleLowerCase();
                const isContainNameBySearch = name.toLocaleLowerCase().includes(searched);
                const isContainLocationBySearch = location.toLocaleLowerCase().includes(searched);
                const isContaintypeBySearch = type.join(" ").toLocaleLowerCase().includes(searched);

                return isContainNameBySearch || isContainLocationBySearch || isContaintypeBySearch;
            }

            const isContainType = type.indexOf(filters.type) !== -1;
            const isContainLocation = location.toLocaleLowerCase().includes(filters.location.toLocaleLowerCase());
            if(filters.location && filters.type) return isContainType && isContainLocation;
            else if(filters.location) return isContainLocation;
            else if(filters.type) return isContainType;
            return data;
        });
        
        return <>
        
            {filteredProps.map((detail,i)=>{
                const {cover  , city , types , id, name_en} = detail;
                const link = "/properties/" +id + "/" + slugify(name_en);
                const langUrl = (lang ==="ar")? "/ar": "";
                return(
                    <Fade key={"name_" + lang + "-" + i} timeout={1000} in={true}>
                        <div className="service-details-wrapper">
                            <LazyLoad> 
                                <Link to={langUrl+link}><img src={cover[0].image_url} alt={cover[0]["caption_" + lang]}/></Link>
                            </LazyLoad>
                            <div className="service-details">
                                <div className="service-name">
                                    <h2>{detail["name_" + lang]}</h2>
                                    <p>{city["name_" + lang]} / {city.country["name_" +lang]}</p>
                                    <p style={{textTransform: "capitalize"}}>{types.map((item)=> item["name_" +lang]).join(', ').toLocaleLowerCase()}</p>
                                </div>
                                <Link to={langUrl+link}>{(lang==="ar")? 'عرض المشروع': "View Project"}</Link>
                            </div>
                        </div>
                    </Fade>
                )
            })}
        </>;
    }

    filterChange = (filters, reset) => {
        this.setState({filters});

        if(reset) {
            this.setState({searchField: ''});
        }
    }

    onSearchchange= (event) => {
        this.setState({searchField: event.target.value});
    }

    render (){
        const {filters, searchField, data, lang} = this.state;

        return (
            <div className="properties-page ">
            {(data)? 
                <PageSection>   
                    <Left >
                        <div className="left-section-wrapper">
                            <strong className="property-filter">{(lang === "ar")? 'مصنف بواسطة': "Filter By"}</strong>
                            <Filter onChange={this.filterChange} />
                            <TextField label={(lang === "ar")? 'بحث': "SEARCH"} value={searchField} className="search-field" onChange={(this.onSearchchange)}/>
                        </div>
                    </Left>
                    <Right className={"properties-page-content"}>
                        {(data)? this.renderContent(filters, searchField) : ""}
                    </Right>
                </PageSection>
            :  <Loader className="loader" type="TailSpin" color="#80008D" height={100} width={100} />}
            </div>
        );
    }
}

export default withRouter(props => <Properties {...props}/>);