import React from 'react';
import {withRouter} from "react-router-dom";
import Loader from "react-loader-spinner";
import Api from './../../api/api';
import ArticleComponent from './../article-component';
import ListSection from "./../listSection";

class CreditRating extends React.Component{


    state = {
        data: null,
        lang:"",
    }

    whatPage = () => {
        const {pathname} = this.props.location;
        return pathname;
    }

    setLang = (whatPage) => {
        let lang = 'en';
        if (whatPage.match(/^\/ar/gm)) { 
            lang = 'ar';
            document.title ="التصنيف الائتماني / الارجان / تطوير عقاري";
        }
        else{
            document.title ="CREDIT RATING / Alargan / Real Estate Development";
        }

        this.setState({lang: lang});
    }

    componentDidMount(){
        const whatPage = this.whatPage();
        this.setLang(whatPage);
        
        Api.getAllCreditRatings().then((response)=>{
            this.setState({data: response.data})
        });
        
    }

    renderCreditRating = (data, lang) => {
        const {credit_ratings} = data;
        return (
            <div className="creditrating-downloads-section">
                {credit_ratings.map((item,i)=>{
                    const className = (i === 0)? "first-item": "";
                    return (
                        <ArticleComponent
                            key={item["credit_ratings" + lang] + "-" + i}
                            title={item["name_" + lang]}
                            link={item.download_link}
                            linkIcon={(lang === "ar")? "تحميل":"Download"}
                            className={className}
                        />
                    )
                })}
            </div>
        );
    }

    listDetailsMapping = (data, lang) => {
        let obj = {};
        let arr = [];
        for( let i in data) {
            const keyObj = data[i].key.split("_row_");
            const identifier = keyObj[0];
            const titleOrValueKey = keyObj[1];
            if (titleOrValueKey !== "title" && titleOrValueKey !== "value") continue;
            if(!obj[identifier]) obj[identifier] = {};
            obj[identifier][titleOrValueKey] = data[i]["value_" + lang];
        }
        for (let key in obj){
            arr.push({number : obj[key].title, text : obj[key].value })
        }
        return arr;
    }

    renderItalicSection = (lang) => {
        return <div className="creditrating-italic-section">
            <p className="italic">{ (lang !== "ar")? 
            "*ALARGAN provides the press releases for information purposes only. ALARGAN does not endorse CI ratings or views and does not accept any responsibility for their accuracy."
            :"*تقوم الأرجان بتقديم هذا المحتوى للمعلومات العامة حصرا، ولا تقترح فرض المحتوى أو أي آراء منشورة من قبل كابيتال انتليجنس . كما أن الشركة غير مسؤولة عن أية أخطاء أو عدم دقة في المحتوى الصادر عن الوكالة."}
            </p>
        </div>
    }

    render (){
        const {data, lang}= this.state;

        return (
            <div className="credit-rating-sections">
                {(data) ?
                    <>
                        <h2 className="bold">{data.credit_ratings_data[6]["value_" + lang]}</h2>
                        <span>{data.credit_ratings_data[7]["value_" + lang]}</span>
                        <ListSection listDetails={this.listDetailsMapping(data.credit_ratings_data, lang)}/>
                        {this.renderItalicSection(lang)}
                        {this.renderCreditRating(data,lang)}
                    </>
                : <Loader className="loader" type="TailSpin" color="#80008D" height={100} width={100} />}
            </div>
        );
    }
}

export default withRouter(props => <CreditRating {...props}/>);
