import React from 'react';
import {withRouter,Link} from "react-router-dom";
import {PageSection, Left, Right} from './../pageSection/pageSection.js';
import Fade from '@material-ui/core/Fade';
import LazyLoad  from 'react-lazyload';

class PropertiesSearch extends React.Component{

    state = {
        lang: ''
    }

    componentDidMount(){
        this.setLang(this.whatPage());
    }

    setLang = (isPage) => {
        let lang = '';

        if (isPage.match(/^\/ar/gm)) { 
            lang = 'ar';
        }
        
        if(lang) {
            this.setState({lang: '/' + lang});
        }else{
            this.setState({lang: 'en'});
        }
    }

    whatPage = () => {
        const {pathname} =  this.props.location;
        return pathname;
    }

    renderRightSection = (data) => {
        const {lang} = this.state;
        const langAbrr = (lang === '/ar')? 'ar': 'en';

        const {detail, cover, city, types, link} = this.props;
        return (
            <Fade  timeout={1000} in={true}>
            <div className="service-details-wrapper">
                <LazyLoad> 
                    <Link to={link}><img src={cover[0].image_url} alt={cover[0]["caption_" + langAbrr]}/></Link>
                </LazyLoad>
                <div className="service-details">
                    <div className="service-name">
                        <h2>{detail}</h2>
                        <p>{city["name_" + langAbrr]} / {city.country["name_" +langAbrr]}</p>
                        <p style={{textTransform: "capitalize"}}>{types.map((item)=> item["name_" +langAbrr]).join(', ').toLocaleLowerCase()}</p>
                    </div>
                    <Link to={link}>{(langAbrr === "ar")? 'عرض المشروع': "View Project"}</Link>
                </div>
            </div>
            </Fade>
        );
    }

    render (){
        const {className} = this.props
        return (
            <div className={`media-page ${className || ""}`}>
                <>   
                        <>
                            {this.renderRightSection()}
                        </>
                </>
            </div>
        );
    }
}

export default withRouter(props => <PropertiesSearch {...props}/>);