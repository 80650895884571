import React from 'react';
import {withRouter, Link} from "react-router-dom";
import Api from './../../api/api';
import Loader from "react-loader-spinner";
import ShowMore from './../../components/showMore';
import ArticleComponent from './../article-component';

class KeyFinancials extends React.Component{


    state = {
        data: null,
        lang:"",
    }

    whatPage = () => {
        const {pathname} = this.props.location;
        return pathname;
    }

    setLang = (whatPage) => {
        let lang = 'en';
        if (whatPage.match(/^\/ar/gm)) { 
            lang = 'ar';
            document.title ="التقارير السنوية / الارجان / التقارير السنوية";
        }
        else{
            document.title ="ANNUAL REPORTS / Alargan / Real Estate Development";
        }

        this.setState({lang: lang});
    }

    componentDidMount(){
        const whatPage = this.whatPage();
        this.setLang(whatPage);
        
        Api.getAllFinancialKeys().then((response)=>{
            this.setState({data: response.data.financial_keys})
        });
        
    }


    getDataWithChunks = (iterator, chunkedData, itemId) =>{
        const {lang} = this.state;
        this.setState({
            ["renderedDisclosures" + itemId]: <> {
                chunkedData.map((chunkedItem, i)=>{
                  return <>{(i <= iterator)? 
                  <>{ 
                     chunkedItem.map((item, j)=> <ArticleComponent
                            key={item["name_" + lang] + "-" + i}
                            title={item["name_" + lang]}
                            link={item.download_link}
                            linkIcon={(lang === "ar")? "تحميل":"Download"}
                            className={(j === 0 && i ===0)? "first-item": ""}
                        />
                     )
                  }</>
                  : <></>}</>
                })
             } </>
        });

    }

    renderSection = (data, lang, index) => {
        const renderedDisclosures = this.state['renderedDisclosures'+index] || <></>;

        return (
            <div key={"key-financial-section-" + data["name_" + lang] + data.id} className="key-financial-section article-component-section-listing">
                <h2 className="bold">{data["name_" + lang]}</h2>
                {renderedDisclosures}
                <ShowMore 
                    data={data.financial_types}
                    chunkSize={4}
                    itemId={index}
                    onChange={this.getDataWithChunks}
                    showMoreText = {(lang === "ar")? "أظهر المزيد": "Show More"}
                    className="article-component-section-link"
                />
            </div>
        );
    }

    render (){
        const {data, lang} = this.state;

        return (
            <div className="key-financial-sections">
                {(data)? 
                    <>
                        {data.map((financialKey, index)=>{
                            return this.renderSection(financialKey, lang, index)
                        })}
                    </>
                : <Loader className="loader" type="TailSpin" color="#80008D" height={100} width={100} /> }
                
            </div>
        );
    }
}

export default withRouter(props => <KeyFinancials {...props}/>);
