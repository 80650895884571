import React from 'react';
import {withRouter} from "react-router-dom";
import qs from 'query-string';


import downArr from './../../assets/img/down-arr.svg';
import upArr from './../../assets/img/up-arr.svg';

class Filter extends React.Component{

    state = {
        location: "",
        type: "",
        isOpened: false, // values are location or type or false if both closed,
        lang:"",
        oldLocationUrl: this.props.location
    };

    whatPage = () => {
        const {pathname} = this.props.location;
        return pathname;
    }

    setLang = (whatPage) => {
        let lang = 'en';
        if (whatPage.match(/^\/ar/gm)) { 
            lang = 'ar';
        }

        this.setState({lang: lang});
    }

    listenerToUrlChange = () => {
        this.unlisten = this.props.history.listen((location, action) => {
            if(location.state && location.state.reset === true) {
                const setFilter = {
                    location: location.state.filters.location, 
                    type: location.state.filters.type,
                };
                this.setState(setFilter);
                this.props.onChange(setFilter, true);
            }
        });
    } 

    componentWillUnmount(){
        this.unlisten();
    }


    componentDidMount () {
        const whatPage = this.whatPage();
        this.setLang(whatPage);
        this.listenerToUrlChange();

        this.initFilter();
    }

    initFilter = () => {
        this.setFilterByQueryStr('location');
        this.setFilterByQueryStr('type');
    }

    setFilterByQueryStr = (filter) => {
        const {search} = this.props.location;
        // alert("search" +  search);
        const queryStr = new URLSearchParams(search);
        const filterValue = queryStr.get(filter);
        if (filterValue) this.setFilter(filter, filterValue);
        
        return false;
    }
    
    setFilter = (filter, value) =>{
        this.setState({
            [filter]: value
        },
        ()=> {
            const {location, type} = this.state;
            this.props.onChange({location, type});
            
            // on filter close
            this.setState({isOpened: false});

            const {history} = this.props;

            let searchStringObj = {}
            if(location) searchStringObj = {location, ...searchStringObj};
            if(type) searchStringObj = {type, ...searchStringObj};

            history.replace({
                'search': qs.stringify(searchStringObj)
            });
        })
    }

    onToggle = (filter) =>{
        const {isOpened} = this.state;
        // if it was opened previously close it by false
        let openState = false;
        if(filter !== isOpened) {
            openState = filter;
        }
        this.setState({isOpened: openState});
    }

    render (){
        const {lang} =this.state;
        const filters = [
            {
                name_en: "location",
                name_ar: "الموقع",
                items: [(lang ==="ar")? 'كويت': "kuwait" , (lang==="ar")? 'عمان': "oman", (lang==="ar")? 'المغرب': "morocco" , (lang==="ar")? 'بحرين': "bahrain"],
            },
            {
                name_en: "type",
                name_ar: "النوع",
                items: [(lang==="ar")? 'سكني': "residential", (lang==="ar")? 'تعليمي': "education", (lang==="ar")? 'تجاري': "commercial", (lang==="ar")? 'الضيافة': "hospitality" , (lang==="ar")? 'الصحة': "health"],
            },
        ];

        const {isOpened} = this.state;
        return (
            
            <div className="filters">
                {filters.map((filter, i) => {
                
                let openState = isOpened && (isOpened === filter["name_" + lang]);

                return <div key={"name-" + i} className="filter" >
                    <div onClick={()=>this.onToggle(filter["name_" + lang])} className="filter-title">
                        <p>{(this.state[filter["name_en"]])? this.state[filter["name_en"]] : filter["name_" + lang]}</p>
                        <img src={(openState)? upArr: downArr} alt="arrow"/>
                    </div>
                    <div className={`filter-options ${!openState? "d-none": ''}`}>
                        {filter.items.map((item, i)=>{
                            const selected = (item === this.state[filter["name_" + lang]]);
                            return <p className={selected? "bold" : ""} onClick={()=>this.setFilter(filter["name_en"], item)} key={item + "-" + i}>{item}</p>
                        })}
                        <p onClick={()=>this.setFilter(filter["name_en"], "")}>{(lang==="ar")? 'كل المشاريع': "ALL"}</p>
                    </div>
                    
                </div>
                })}

                
            </div>
        );
        
    }
}

export default withRouter(props => <Filter {...props}/>);

