import React from 'react';
import {withRouter, Link} from "react-router-dom";
import Api from './../../api/api';
import slugify from 'react-slugify';

import logo from '../../assets/img/logo.svg';
import miniLogo from '../../assets/img/simple-logo.svg';
import searchIcon from '../../assets/img/search-icon.svg';
import cardImg11 from '../../assets/img/banner-11.jpg';
import closeBtn from '../../assets/img/Group-629.svg';

class DesktopHeader extends React.Component {
    state = {
        menuOpened: false,
        scrolled: 0,
        lang: '',
        data:'',
    }

    componentDidMount(){
        window.onscroll = ()=>{
            this.setState({scrolled: window.pageYOffset});
        };

        Api.getHeaderFeaturedProperty().then((response)=>{
            this.setState({data: response.data.project})
        });

        this.setLang(this.whatPage());
        this.listenerToUrlChange();
    }

    setLang = (isPage) => {
        let lang = '';
        if (isPage.match(/^\/ar/gm)) {
            lang = 'ar';
        }
        
        if(lang) {
            this.setState({lang: '/' + lang});
        }else{
            this.setState({lang: ''});
        }
    }

    listenerToUrlChange = () => {
        const {location} = this.props.history;

        if (location.pathname.match(/^\/ar/gm)) { 
            document.body.classList.add("ar");
        }
        else{
            document.body.classList.remove("ar");
        }
       
        this.unlisten = this.props.history.listen(location => {
            if (location.pathname.match(/^\/ar/gm)) { 
                document.body.classList.add("ar");
            }
            else{
                document.body.classList.remove("ar");
            }
        });
    } 

    componentWillUnmount(){
        this.unlisten();
    }

    whatPage = () => {
        const {pathname} =  this.props.location;
        return pathname;
    }

    renderLogos = () => {
        const {lang} = this.state;
        const langAbbr = lang.split("/").join("");
        return (
            <div className="dmenu-center image-logo">
                <Link onClick={(e)=>this.setMenuClosed(e)} to={"/" + langAbbr}>
                    <img 
                        className="lg-logo" 
                        src={logo} alt="alargan logo"
                    />
                    <img 
                        className="sm-logo" 
                        src={miniLogo} 
                        alt="alargan logo"
                    />
                </Link>
            </div>
        );
    }

    renderRightSection = () => {
        const page = this.whatPage();
        const {lang} = this.state;
        const isContactPage = (page.indexOf("/contact") !== -1)? 'active': '';
        return (
            <nav className="dmenu-right dmenu">
                <ul className="dmenu-ul">
                    <li>
                        <Link onClick={(e)=>this.setMenuClosed(e)} className="search-action" to={lang + "/search"}>
                            <img src={searchIcon} alt="search" />
                        </Link>
                    </li>
                    <li>{(this.state.lang === '')?
                        <a className="arabic-btn" onClick={(e)=>{this.setMenuClosed(e)}} href="/ar">عربي</a> 
                        // <a className="arabic-btn" onClick={(e)=>{this.setMenuClosed(e)}}>عربي</a> 
                        : 
                        <a className="english-btn" onClick={(e)=>{this.setMenuClosed(e)}} href="/">English</a> 
                        }</li>
                    <li
                        className={isContactPage}
                    ><Link  className="contact-btn" onClick={(e)=>this.setMenuClosed(e)} to={this.state.lang +"/contact-us"}>{this.state.lang? "اتصل بنا" : "CONTACT US"}</Link></li>
                </ul>
            </nav>
        );
    }

    renderAboutMenu = () => {
        const {menuOpened,lang} = this.state;
        const openClass = (menuOpened === 'about')? 'open': '';
        return (
            <div className={"content-container " + openClass}>
                <div className="content-wrapper content-about">
                    <div className="header-nav-section">
                        <div className="header-nav-content-left">
                            <strong className="header-nav-content-subtitle">{lang? "الشركة" : "THE FIRM"}</strong>
                            {/* lang? "ar": "en" */}
                            <Link onClick={(e)=>this.setMenuClosed(e)} to={lang + "/firm"}>{lang? "نبذة عن الشركة" : "PROFILE"}</Link>
                            <Link onClick={(e)=>this.setMenuClosed(e)} to={lang +"/firm/history"}>{lang? "نبذة تاريخية" : "HISTORY"}</Link>
                            <Link onClick={(e)=>this.setMenuClosed(e)} to={lang +"/firm/sustainability"}>{lang? "الاستدامة" : "SUSTAINABILITY"}</Link>
                            <Link onClick={(e)=>this.setMenuClosed(e)} to={lang +"/firm/social"}>{lang? "المسئولية الإجتماعية" : "SOCIAL RESPONSIBILITY"}</Link>
                            <Link onClick={(e)=>this.setMenuClosed(e)} to={lang +"/firm/awards"}>{lang? "الجوائز والشهادات التقديرية" : "AWARDS & RECOGNITIONS"}</Link>
                            <Link onClick={(e)=>this.setMenuClosed(e)} to={lang +"/firm/leadership"}>{lang? "الإدارة" : "LEADERSHIP"}</Link>
                        </div>
                        <div className="header-nav-content-right">
                            <strong className="header-nav-content-subtitle">{lang? "الخبرة" : "EXPERTISE"}</strong>
                            <Link onClick={(e)=>this.setMenuClosed(e)} to={lang +"/expertise"}>{lang? "نظرة عامة" : "OVERVIEW"}</Link>
                            <Link onClick={(e)=>this.setMenuClosed(e)} to={lang +"/expertise/services"}>{lang? "الخدمات" : "SERVICES"}</Link>
                            <Link onClick={(e)=>this.setMenuClosed(e)} to={lang +"/expertise/sectors"}>{lang? "القطاعات" : "SECTORS"}</Link>
                            <Link onClick={(e)=>this.setMenuClosed(e)} to={lang +"/expertise/locations"}>{lang? "المواقع" : "LOCATIONS"}</Link>
                        </div>
                        <span className="bold cursor-pointer close-btn" onClick={(e)=>this.setMenuClosed(e)}><img src={closeBtn} alt="" /></span>
                    </div>
                    <div className="header-nav-bottom">
                        <strong className="header-nav-content-subtitle">{lang? "استكشاف المزيد" : "EXPLORE MORE"}</strong>
                        <div className="header-explore-section">
                            <div><Link onClick={(e)=>this.setMenuClosed(e)} to={lang +"/investor"}>{lang? "علاقات المستثمرين" : "INVESTOR RELATIONS"}</Link> <Link onClick={(e)=>this.setMenuClosed(e)} to={lang +"/investor"}>{lang? "←" : "→"}</Link></div>
                            <div><Link onClick={(e)=>this.setMenuClosed(e)} to={lang +"/governance"}>{lang? "حوكمة الشركات" : "COPRORATE GOVERNANCE"}</Link> <Link onClick={(e)=>this.setMenuClosed(e)} to={lang +"/governance"}>{lang? "←" : "→"}</Link></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderPropertiesMenu = (data) => {
        const {menuOpened, lang} = this.state;
        const langAbbr = (!lang)? "en" : "ar";
        const {cover} = data;
        const openClass = (menuOpened === 'properties')? 'open': '';
        return (
            <div className={"content-container " + openClass}>
                <div className="content-wrapper content-properties">
                <div className="header-nav-section">
                        <div className="header-nav-content-left">
                        
                            <strong className="header-nav-content-subtitle">{lang? "حسب النوع" : "BY TYPE"}</strong>
                            <Link onClick={(e)=>this.setMenuClosed(e)} 
                            to={{
                                pathname: lang + "/properties",
                                search: "?type=" + (lang? "سكني" : "residential"),
                                state: { filters: {location: "", type: (lang? "سكني" : "residential")}, reset: true }
                                }}
                            >
                                {lang? "سكني" : "RESIDENTIAL"}
                            </Link>
                            <Link onClick={(e)=>this.setMenuClosed(e)} 
                                to={{
                                    pathname: lang + "/properties",
                                    search: "?type=" + (lang? "تعليمي" : "education"),
                                    state: { filters: {location: "", type: (lang? "تعليمي" : "education")}, reset: true }
                                }}
                            >
                                {lang? "تعليمي" : "EDUCATION"}
                            </Link>
                            <Link onClick={(e)=>this.setMenuClosed(e)} 
                                to={{
                                    pathname: lang + "/properties",
                                    search: "?type=" + (lang? "تجاري" : "commercial"),
                                    state: { filters: {location: "", type: (lang? "تجاري" : "commercial")}, reset: true }
                                }}
                            >
                                {lang? "تجاري" : "COMMERCIAL"}
                            </Link>
                            <Link onClick={(e)=>this.setMenuClosed(e)} 
                                to={{
                                    pathname: lang + "/properties",
                                    search: "?type=" + (lang? "الضيافة" : "hospitality"),
                                    state: { filters: {location: "", type: (lang? "الضيافة" : "hospitality")}, reset: true }
                                }}
                            >
                                {lang? "الضيافة" : "HOSPITALITY"}
                            </Link>
                            <Link onClick={(e)=>this.setMenuClosed(e)} 
                                to={{
                                    pathname: lang + "/properties",
                                    search: "?type=" + (lang? "الصحة" : "health"),
                                    state: { filters: {location: "", type: (lang? "الصحة" : "health")}, reset: true }
                                }}
                            >
                                {lang? "الصحة" : "HEALTH"}
                            </Link>
                            <Link  className="all-projects" onClick={(e)=>this.setMenuClosed(e)} 
                                to={{
                                    pathname: lang + "/properties",
                                    state: { filters: {location: "", type: ""}, reset: true }
                                }}
                            >
                                {lang? "كل المشاريع" : "ALL PROJECTS"}
                            </Link>
                        </div>
                        <div className="header-nav-content-right">
                            <strong className="header-nav-content-subtitle">{lang? "حسب الموقع" : "BY LOCATION"}</strong>
                            <Link onClick={(e)=>this.setMenuClosed(e)} 
                                to={{
                                    pathname: lang + "/properties",
                                    search: "?location=" + (lang? "الكويت" : "kuwait"),
                                    state: { filters: {location: (lang? "الكويت" : "kuwait"), type: ''}, reset: true }
                                }}
                            >
                                {lang? "الكويت" : "KUWAIT"}
                            </Link>
                            <Link onClick={(e)=>this.setMenuClosed(e)} 
                                to={{
                                    pathname: lang + "/properties",
                                    search: "?location=" + (lang? "عمان" : "oman"),
                                    state: { filters: {location: (lang? "عمان" : "oman"), type: ''}, reset: true }
                                }}
                            >
                                {lang? "عمان" : "OMAN"}
                            </Link>
                            <Link onClick={(e)=>this.setMenuClosed(e)} 
                                to={{
                                    pathname: lang + "/properties",
                                    search: "?location=" + (lang? "المغرب" : "morocco"),
                                    state: { filters: {location: (lang? "المغرب" : "morocco"), type: ''}, reset: true }
                                }}
                            >
                                {lang? "المغرب" : "MOROCCO"}
                            </Link>
                            <Link onClick={(e)=>this.setMenuClosed(e)} 
                                to={{
                                    pathname: lang + "/properties",
                                    search: "?location=" + (lang? "البحرين" : "bahrain"),
                                    state: { filters: {location: (lang? "البحرين" : "bahrain"), type: ''}, reset: true }
                                }}
                            >
                                {lang? "البحرين" : "BAHRAIN"}
                            </Link>
                        </div>
                        <span className="bold cursor-pointer close-btn" onClick={(e)=>this.setMenuClosed(e)}><img src={closeBtn} alt="" /></span>
                    </div>
                    <div className="header-bottom-section">
                        <div className="header-bottom-left">
                            <strong className="header-nav-content-subtitle">{lang? "منزلك التالي" : "YOUR NEXT HOME"} </strong>
                            {(data)? 
                                <img src={cover[0].image_url} alt="properties img" />
                            :''}
                            <div className="header-bottom-left-content">
                                <span>{data["name_" + langAbbr]}</span>
                                <Link onClick={(e)=>this.setMenuClosed(e)}  to={lang+"/properties/" + data.id + "/" + slugify(data.name_en)}>{lang? "عرض المشروع" : "View Project"}</Link>
                            </div>
                        </div>
                        <div className="header-bottom-right">
                            <span className="life">{lang? "الحياة كما" : "LIFE AS"} <br/>{lang? "تحبّها" : "YOU LOVE IT"} </span>
                            <span className="hashtag">
                                <span>{lang? "#حياة_الارجان" : "#AlarganLife"}</span>
                                {/* <Link onClick={(e)=>this.setMenuClosed(e)} to="/media/lifestyle">{lang? "استكشف" : "Explore"}</Link> */}
                                <a onClick={(e)=>this.setMenuClosed(e)}>{lang? "استكشف" : "Explore"}</a>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    setMenuOpen = (menu) =>{
        this.setState({menuOpened: menu});
    } 
    
    setMenuClosed = (event) =>{
        if(event) event.stopPropagation();
        this.setState({menuOpened: false});
    }

    renderLeftSection = () => {
        const {menuOpened, lang, data} = this.state,
        aboutActive = (menuOpened === 'about')? 'active': '',
        propertiesActive = (menuOpened === 'properties')? 'active': '';
        const page = this.whatPage();
        const isAboutPage = (page.indexOf("/firm") !== -1)? 'active': '';
        const isPropertyPage = (page.indexOf("/properties") !== -1)? 'active': '';
        return (
            <nav className="dmenu-left dmenu">
                <ul className="dmenu-ul">
                    <li
                        className={aboutActive || isAboutPage}
                        onClick={()=>this.setMenuOpen("about")} 
                    >
                        <Link onClick={(e)=>{e.preventDefault()}} to="">{(lang === '' )? "ABOUT" : "من نحن"}</Link>
                        {this.renderAboutMenu()}
                    </li>
                    <li
                        className={propertiesActive || isPropertyPage}
                        onClick={()=>this.setMenuOpen("properties")}
                    >
                        <Link onClick={(e)=>{e.preventDefault()}} to="">{(lang === '' )? "PROPERTIES" : "المشاريع"}</Link>
                        {this.renderPropertiesMenu(data)}
                    </li>
                </ul>
            </nav>
        );
    }
    
    render (){
        const {menuOpened, scrolled} = this.state;
        const {isHomePage} = this.props;
        // if menu opened => open class
        // or if not home page => open class
        // or if home page but scrolled => open class
        const homePageScrolled = isHomePage && (scrolled > 84);
        const openClass = (menuOpened || !isHomePage || homePageScrolled)? 'open': '';
        return (
            <div className={`dmenu-container ${openClass} ${isHomePage? "dmenu-home" : ""}`}>
                <div className="dmenu-wrapper">
                    {this.renderLeftSection()}
                    {this.renderLogos()}
                    {this.renderRightSection()}
                </div>
            </div>          
        );
    }
}

export default withRouter(props => <DesktopHeader {...props}/>);
