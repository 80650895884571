import React from 'react';
import {withRouter} from "react-router-dom";
import Api from './../../api/api';
import Loader from "react-loader-spinner";

import ListSection from "./../listSection";

class Overview extends React.Component{

    state = {
        data: null,
        lang:"",
    }

    whatPage = () => {
        const {pathname} = this.props.location;
        return pathname;
    }

    setLang = (whatPage) => {
        let lang = 'en';
        if (whatPage.match(/^\/ar/gm)) { 
            lang = 'ar';
            document.title ="علاقات المستثمرين / الارجان / تطوير عقاري";
        }
        else{
            document.title ="Investor Relations / Alargan / Real Estate Development";
        }

        this.setState({lang: lang});
    }

    componentDidMount(){
        const whatPage = this.whatPage();
        this.setLang(whatPage);
        
        Api.getAllnverstorOverview().then((response)=>{
            this.setState({data: response.data})
        });
        
    }

    listDetailsMapping = (data, lang) => {
        return data.map((item,i)=>{
            return {
                number:item["value_" + lang],
                text: item["name_" + lang]
            }
        })
    }

    createMarkup = (data) => {
        return {__html: data};
    }

    render (){

        const {data, lang} = this.state;

        return (
            <div className="overview-page">
                {(data)? 

                    <>
                        <div className="overview-wrapper">
                            <p dangerouslySetInnerHTML={this.createMarkup( data.investorOverview["value_" + lang])}/>
                        </div>
                        <ListSection listDetails={this.listDetailsMapping(data.items, lang)}/>
                    </>
                : <Loader className="loader" type="TailSpin" color="#80008D" height={100} width={100} />}
            </div>
        );
    }
}

export default withRouter(props => <Overview {...props}/>);