import cachios from 'cachios';
import axios from 'axios';

// import querystring from 'querystring';

class Api {
  baseUrl = 'https://alargan.app/api/v1';
  // baseUrl = 'https://alargan-api.test/api/v1';

  sendForm = async (data) => {
    const url = this.baseUrl + `/contact-form`;
    return await axios.post(url, data);
  }
  sendCareerForm = async (data) => {
    console.log(data)
    const formData = new FormData();

    for (let key in data) {
      const val = data[key];
      formData.append(key, val);
    }
    
    const url = this.baseUrl + `/careers-form`;
    return await axios.post(url, formData);
  }

  getHome = async () => {
    return await cachios.get(this.baseUrl + `/home`);
  }
  getHeaderFeaturedProperty = async () => {
    return await cachios.get(this.baseUrl + `/menu-project`);
  }

  getProjects = async () => {
    return await cachios.get(this.baseUrl + `/projects`);
  }

  getProjectsList = async () => {
    return await cachios.get(this.baseUrl + `/projects-names`);
  }

  getSingleProject = async (id) => {
    return await cachios.get(this.baseUrl + `/projects/` + id);
  }

  getAllJobs = async () => {
    return await cachios.get(this.baseUrl + `/jobs`);
  }

  getAllSiteData = async () => {
    return await cachios.get(this.baseUrl + `/data_sites`);
  }

  getSpecificSiteData = async (name) => {
    return await cachios.get(this.baseUrl + `/data_sites/` + name);
  }

  getAllContacts = async () => {
    return await cachios.get(this.baseUrl + `/contacts`);
  }

  getAllMediaSites = async () => {
    return await cachios.get(this.baseUrl + `/media_sites`);
  }

  getSpecificMediaSite = async (name) => {
    return await cachios.get(this.baseUrl + `/media_sites/` + name);
  }

  getAllCenterMedias = async () => {
    return await cachios.get(this.baseUrl + `/center_medias`);
  }

  getAllPressKits = async () => {
    return await cachios.get(this.baseUrl + `/press_kits`);
  }

  getAllPressReleases = async () => {
    return await cachios.get(this.baseUrl + `/press_releases`);
  }

  getAllblogs = async () => {
    return await cachios.get(this.baseUrl + `/blogs`);
  }

  getSingleBlog = async (id) => {
    return await cachios.get(this.baseUrl + `/blogs/` + id);
  }

  getSearchData = async (query) => {
    return await cachios.get(this.baseUrl + `/search/` + query);
  }

  getAllProfileData = async () => {
    return await cachios.get(this.baseUrl + `/profile`);
  }

  getAllHistory = async () => {
    return await cachios.get(this.baseUrl + `/history`);
  }

  getAllSustainability = async () => {
    return await cachios.get(this.baseUrl + `/sustainability`);
  }

  getAllSocialResponsibilityData = async () => {
    return await cachios.get(this.baseUrl + `/social_responsibility`);
  }

  getAllAwardsRecognitionsData = async () => {
    return await cachios.get(this.baseUrl + `/awards_recognitions`);
  }

  getAllLeadershipMembersData = async () => {
    return await cachios.get(this.baseUrl + `/leadership`);
  }

  getSingleLeadershipMemberData = async (id) => {
    return await cachios.get(this.baseUrl + `/leadership_members/` + id);
  }

  getAllServices = async () => {
    return await cachios.get(this.baseUrl + `/services` );
  }

  getAllSectors = async () => {
    return await cachios.get(this.baseUrl + `/sectors` );
  }

  getSingleSector = async (id) => {
    return await cachios.get(this.baseUrl + `/sectors/` +id );
  }

  getAllLocations = async () => {
    return await cachios.get(this.baseUrl + `/locations` );
  }

  getSignleLocation = async (id) => {
    return await cachios.get(this.baseUrl + `/locations/` + id );
  }
//recieved
  getAllFinancialKeys = async () => {
    return await cachios.get(this.baseUrl + `/financial_keys` );
  }

  getAllDisclosureYears = async () => {
    return await cachios.get(this.baseUrl + `/disclosure_years` );
  }

  getAllCreditRatings = async () => {
    return await cachios.get(this.baseUrl + `/credit_ratings` );
  }

  getAllAgmDocuments = async () => {
    return await cachios.get(this.baseUrl + `/agm_documents` );
  }
//not recieved
  getAllShareholdersDividends = async () => {
    return await cachios.get(this.baseUrl + `/shareholders_dividends` );
  }

  getAllnverstorOverview = async () => {
    return await cachios.get(this.baseUrl + `/investor-relations` );
  }

  getAllAnalystCoverages = async () => {
    return await cachios.get(this.baseUrl + `/analyst_coverages` );
  }

  getAllFinancialCalendar = async () => {
    return await cachios.get(this.baseUrl + `/financial_calendar` );
  }

  getAllCandidateApplication = async () => {
    return await cachios.get(this.baseUrl + `/candidate_application` );
  }

  getAllPolicies = async () => {
    return await cachios.get(this.baseUrl + `/policies` );
  }

  getAllNews = async () => {
    return await cachios.get(this.baseUrl + `/news` );
  }

  getAllExpertiseOverview = async () => {
    return await cachios.get(this.baseUrl + `/data_sites/expertise` );
  }

  getAllClientComplaints = async () => {
    return await cachios.get(this.baseUrl + `/data_sites/client_complaints` );
  }

  getAllFrameworks = async () => {
    return await cachios.get(this.baseUrl + `/governance_frameworks` );
  }

  getAllGovernanceOverview = async () => {
    return await cachios.get(this.baseUrl + `/data_sites/corporate_governance_overview` );
  }
  

}
export default new Api();


