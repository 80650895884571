import React from 'react';
import {withRouter, Link} from "react-router-dom";
import {PageSection, Left,  Right} from '../components/pageSection/pageSection';
import MediaCenter from './../components/media/media-center/media-center';
import Lifestyle from './../components/media/lifestyle/lifestyle';
import LifestyleBlog from './../components/media/lifestyle/lifestyleBlog';

class Media extends React.Component{

    state = {
        lang: ''
    }

    componentDidMount(){
        this.setLang(this.whatPage());
    }

    setLang = (isPage) => {
        let lang = '';

        if (isPage.match(/^\/ar/gm)) { 
            lang = 'ar';
        }
        
        if(lang) {
            this.setState({lang: '/' + lang});
        }else{
            this.setState({lang: ''});
        }
    }

    whatPage = () => {
        const {pathname} =  this.props.location;
        return pathname;
    }

    renderLinks = () => {
        const {lang} = this.state;
        const page = this.whatPage();
        switch(page) {
            default:
            case "/ar/media/lifestyle/:id":
            case "/ar/media/lifestyle":
            case "/media/lifestyle/:id":
            case "/media/lifestyle":
                return(
                    <div className="link-section">
                        <Link to={lang +"/media"}>{lang? "أحدث الأخبار والأرشيفات" : "MEDIA CENTER"}</Link>
                        <strong>{lang? "المركز الاعلامي المدونة" : "LIFESTYLE BLOG"}</strong>
                    </div>
                );
            case "/ar/media":
            case "/media":
                return(
                    <div className="link-section">
                        <strong>{lang? "أحدث الأخبار والأرشيفات" : "MEDIA CENTER"}</strong>
                        <Link to={lang +"/media/lifestyle"}>{lang? "المركز الاعلامي المدونة" : "LIFESTYLE BLOG"}</Link>
                    </div>
                );
            
        }
    }

    renderContent = () => {
        const page = this.whatPage();
        switch(page) {
            default:
            case "/ar/media/lifestyle/:id":
            case "/media/lifestyle/:id":
                return <LifestyleBlog />;
                case "/ar/media/lifestyle":
            case "/media/lifestyle":
                return <Lifestyle />;
            case "/ar/media":
            case "/media":
                return <MediaCenter />;
        }
    }

    getClassContentName = () => {
        const page = this.whatPage();
        switch(page) {
            default:
            case "/ar/media":
            case "/media":
                return "mediacenter-page";
            case "/ar/media/lifestyle":
            case "/media/lifestyle":
                return "lifestyle-page";
            case "/ar/media/lifestyle/:id":
            case "/media/lifestyle/:id":
                return "blog-page";
        }
    }

    render (){
        const {lang} = this.state
        return (
            <div className="media-page ">
                <PageSection>   
                        <Left>
                            <strong>{lang? "وسائل الاعلام والمدونة" : "MEDIA & BLOG"}</strong>
                            {this.renderLinks()}
                        </Left>
                        <Right className={this.getClassContentName()}>
                            {this.renderContent()}
                        </Right>
                </PageSection>
            </div>
        );
    }
}

export default withRouter(props => <Media {...props}/>);