import React from 'react';
import {withRouter, Link} from "react-router-dom";
import Api from './../../../api/api';
import Loader from "react-loader-spinner";


class Lifestyle extends React.Component{

    state = {
        data: null,
        lang:"",
        langUrl:''
    }

    whatPage = () => {
        const {pathname} = this.props.location;
        return pathname;
    }

    setLang = (whatPage) => {
        let lang = 'en';
        if (whatPage.match(/^\/ar/gm)) { 
            lang = 'ar';
            document.title ="المركز الاعلامي المدونة / الارجان / تطوير عقاري";
        }
        else{
            document.title ="Lifestyle Blog / Alargan / Real Estate Development";
        }

        this.setState({lang: lang, langUrl:(lang==="en")? '' : '/ar'});
    }

    componentDidMount(){
        const whatPage = this.whatPage();
        this.setLang(whatPage);
        
        Api.getAllblogs().then((response)=>{
            this.setState({data: response.data})
        });
        
    }
    
    blogArticle = (blog, i) => {
        const {lang, langUrl} = this.state;
        const {title, date, info, id, image} = blog;
        return (
            <div key={title + "-" + i} className="blog-article">
                <div className="blog-article-image">
                    <img src={image} alt="blog images"/>
                </div>
                <div className="blog-article-info">
                    <h2 className="bold">{title}</h2>
                    <p className="blog-article-info-date">{date}</p>
                    <p>{info}</p>
                    <Link to={langUrl + "/media/lifestyle/"+id}>{(lang==="ar")? "قراءة المزيد" : "Read More"}</Link>
                </div>
            </div>
        );
    }

    renderBlogs = (data,lang) => {
        const blogs = data.map((blog,i) => {
            return {
                title: blog["title_" +lang],
                date: blog.published_date,
                info:blog["intro_" +lang],
                image: blog.image_url,
                id: blog.id,
            }
        })

        return (
            <>
                {blogs.map((blog,i)=>{
                    return this.blogArticle(blog, i);
                })}
            </>
        )
    }
    

    render (){
        const {data, lang} = this.state;
        return (
            <div className="blog-section">
            {(data) ? 
                <> 
                    {this.renderBlogs(data.blogs, lang)}
                    <div className="images-sections">
                        <h2>{(lang === "en")? "LIFE AS" :"الحياة"} <br/> {(lang === "en")? "YOU LOVE IT" :"كما تحبها"}</h2>

                        {}
                        <div className="images-sections-first-row">
                            <img className="img1" src={data.featured_media[0].media_url} alt="media img"/>
                            <img className="img2" src={data.featured_media[1].media_url} alt="media img"/>
                        </div>
                        <div className="images-sections-second-row">
                            <img className="img1" src={data.featured_media[2].media_url} alt="media img"/>
                            <img className="img2" src={data.featured_media[3].media_url} alt="media img"/>
                            <img className="img3" src={data.featured_media[4].media_url} alt="media img"/>
                        </div>
                    </div>
                </> : <Loader className="loader" type="TailSpin" color="#80008D" height={100} width={100}/>}
            </div>
        );
    }
}

export default withRouter(props => <Lifestyle {...props}/>);