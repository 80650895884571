import React from 'react';
import {withRouter} from "react-router-dom";
import {TextField, Select, MenuItem} from '@material-ui/core';
import Api from './../api/api';


class ContactCard extends React.Component{

    state = {
        lang: '',
        data: null,
        propertySelected:'0',
        userContactInfo:{
            "fullname":"",
            "email":'',
            "phone":'',
            "property":''
        }
    }

    componentDidMount(){
        this.setLang(this.whatPage());

        Api.getProjectsList().then((response)=>{
            this.setState({data: response.data.projects})
        });

        this.listenerToUrlChange();
    }

    listenerToUrlChange = () => {
        const {location} = this.props.history;
        
        if(location.pathname.indexOf("/properties/")>-1) {
            // this.setState({propertySelected: location.pathname.split("/properties/")[1]});
            this.setState({propertySelected: 0})
        }
        else{
            this.setState({propertySelected: 0})
        }

        this.unlisten = this.props.history.listen(location => {
            this.setLang(location.pathname);

            if(location.pathname.indexOf("/properties/")>-1) {
                // this.setState({propertySelected: location.pathname.split("/properties/")[1]});
                this.setState({propertySelected: 0})
            }
            else{
                this.setState({propertySelected: 0})
            }
        });
    } 

    componentWillUnmount(){
        this.unlisten();
    }

    setLang = (isPage) => {
        let lang = '';

        if (isPage.match(/^\/ar/gm)) { 
            lang = 'ar';
        }
        
        if(lang) {
            this.setState({lang: '/' + lang});
        }else{
            this.setState({lang: ''});
        }
    }

    whatPage = () => {
        const {pathname} =  this.props.location;
        return pathname;
    }

    plusIcon = () => {
        return "+";
    }

    submit = (e) => {
            e.preventDefault();
            Api.sendForm(this.state.userContactInfo)
            this.setState({ 
                userContactInfo:{
                    "fullname":"",
                    "email":'',
                    "phone":'',
                    "property":''
                },
                propertySelected: '0'
            })
        
    }

    setContactData = (name, value) => {
        const {userContactInfo} = this.state;
        this.setState({userContactInfo: {
            ...userContactInfo,
            [name]: value
        }})
    }

    getPropertyNameById = (id)=> {
        if(id === 0) return false;
        const {data, lang} = this.state;
        const langAbbr = (lang === "/ar")? "ar" : "en";
        const property = data.filter(property=> property.id === id);
        if(!property.length) return false;
        return property[0]["name_" + langAbbr];
    }

    render (){
        const {lang,data,propertySelected, userContactInfo: {fullname, phone, email}} = this.state;
        const langAbbr = (lang === "/ar")? "ar" : "en";

        return (
            <section className="contact-section" id="contact-card">
            <div className="empty-div"></div>
            {(data)? 
                <div className="contact-wrapper">
                    <h1>{lang? "سجّل اهتمامك" : "WE’D LOVE TO KNOW WHAT YOU’RE LOOKING FOR."}</h1>
                    <form autoComplete="off">
                        <TextField value={fullname} id="name-input" label={(lang === "/ar")? "أدخل اسمك الكامل" : "ENTER YOUR FULL NAME"} onChange={(e)=>{this.setContactData('fullname', e.target.value)}}/>
                        <TextField value={email} onChange={(e)=>{this.setContactData('email', e.target.value)}} label={(lang === "/ar")? "أدخل بريدك الالكتروني" : "ENTER YOUR EMAIL ADDRESS"} type="email"/>
                        <TextField value={phone} onChange={(e)=>{this.setContactData('phone', e.target.value)}} label={(lang === "/ar")? "أدخل رقم هاتفك" : "ENTER YOUR PHONE NUMBER"} type="tel"/>
                        <Select onChange={(e)=>{this.setContactData('property', this.getPropertyNameById(e.target.value))}} value={propertySelected} IconComponent={this.plusIcon} className="select-field">
                            <MenuItem value="0">{(lang === "/ar")? "اختر مشروع" : "SELECT PROPERTY"}</MenuItem>
                            {data.map((property,i)=>{
                                return <MenuItem onClick={()=>this.setState({propertySelected: property.id})} value={property.id} >{property["name_" + langAbbr]}</MenuItem>
                            })}
                        </Select>
                        <div className="action-btns">
                            <button onClick={this.submit} className="submit-btn">{(lang === "/ar")? "ارسال" : "INQUIRE"}</button>
                        </div>
                    </form>
                </div>
                : '' }
            </section> 
        );
    }
}

export default withRouter(props => <ContactCard {...props}/>);
