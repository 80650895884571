import React from 'react';
import {withRouter, Link} from "react-router-dom";


class DescriptionCard extends React.Component{

    state = {
        data: null,
        lang:"",
        langUrl:''
    }

    whatPage = () => {
        const {pathname} = this.props.location;
        return pathname;
    }

    setLang = (whatPage) => {
        let lang = 'en';
        if (whatPage.match(/^\/ar/gm)) { 
            lang = 'ar';
        }

        this.setState({lang: lang, langUrl:(lang==="en")? '' : '/ar'});
    }

    componentDidMount(){
        const whatPage = this.whatPage();
        this.setLang(whatPage);
    }

    render (){
        const {lang, langUrl} = this.state;
        const {image, title, info, link, className} = this.props;
        return (
            <section className={"desc-card " + className }>
                <div className="desc-card-image">
                    <img src={image} alt="desc card img"/>
                </div>    
                <div className="desc-card-text">
                    <h1 className="desc-card-title">{title}</h1>
                    <p className="desc-card-info">{info}</p>
                    <Link className="desc-card-link" to={langUrl + link}>{(lang === "ar")? "اكتشف المزيد":"Find Out More"}</Link>
                </div>
            </section>
        );
    }
}

export default withRouter(props => <DescriptionCard {...props}/>);
