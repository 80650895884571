import React from 'react';
import {withRouter} from "react-router-dom";
import Api from './../../api/api';
import Loader from "react-loader-spinner";
import ListSection from "./../listSection";

class Overview extends React.Component{

    state = {
        data: null,
        lang:"",
    }

    whatPage = () => {
        const {pathname} = this.props.location;
        return pathname;
    }

    setLang = (whatPage) => {
        let lang = 'en';
        if (whatPage.match(/^\/ar/gm)) { 
            lang = 'ar';
            document.title ="حوكمة الشركات / الارجان / تطوير عقاري";
        }
        else{
            document.title ="Corporate Governance / Alargan / Real Estate Development";
        }

        this.setState({lang: lang});
    }

    componentDidMount(){
        const whatPage = this.whatPage();
        this.setLang(whatPage);
        
        Api.getAllGovernanceOverview().then((response)=>{
            this.setState({data: response.data.data})
        });
        
    }

    renderListSection = (data, lang) =>{
        const listDetails = [
            {
                number:data[2]["value_" + lang],
                text:data[1]["value_" + lang]
            },
            {
                number:data[4]["value_" + lang],
                text:data[3]["value_" + lang]
            }
        ];
        return <ListSection listDetails={listDetails}/>
    }

    createMarkup = (data) => {
        return {__html: data};
    }

    render (){
        const {data, lang} = this.state;
        
        return (
            <div className="overview-page">
            {(data)? 
                <>
                    <div className="overview-wrapper">
                        <span className="bold" dangerouslySetInnerHTML={this.createMarkup( data[0]["value_" + lang])}/>,
                    </div>
                    {this.renderListSection(data, lang)}
                </> : <Loader className="loader" type="TailSpin" color="#80008D" height={100} width={100} />}
            </div>
        );
    }
}

export default withRouter(props => <Overview {...props}/>);