import React from 'react';
import {withRouter, Link} from "react-router-dom";

import {PageSection, Left,  Right} from './../components/pageSection/pageSection';
import Overview from './../components/expertise/overview';
import Services from './../components/expertise/services';
import Sectors from './../components/expertise/sectors';
import Locations from './../components/expertise/locations';
import SectorSinglePage from './../components/expertise/sectorSinglePage';
import LocationSinglePage from './../components/expertise/locationSinglePage';



class Expertise extends React.Component{

    state = {
        lang: ''
    }

    componentDidMount(){
        this.setLang(this.whatPage());
    }

    setLang = (isPage) => {
        let lang = '';

        if (isPage.match(/^\/ar/gm)) { 
            lang = 'ar';
        }
        
        if(lang) {
            this.setState({lang: '/' + lang});
        }else{
            this.setState({lang: ''});
        }
    }

    whatPage = () => {
        const {path} =  this.props.match;
        return path;
      }

    renderLinks = () => {
        const {lang} = this.state;
        const page = this.whatPage();
        switch(page) {
            default:
            case "/ar/expertise":
            case "/expertise":
                return(
                    <div className="link-section">
                        <strong>{lang? "نظرة عامة" : "OVERVIEW"}</strong>
                        <Link to={lang +"/expertise/services"}>{lang? "الخدمات" : "SERVICES"}</Link>
                        <Link to={lang +"/expertise/sectors"}>{lang? "القطاعات" : "SECTORS"}</Link>
                        <Link to={lang +"/expertise/locations"}>{lang? "المواقع" : "LOCATIONS"}</Link>
                    </div>
                );
            case "/ar/expertise/services":
            case "/expertise/services":
                return(
                    <div className="link-section">
                        <Link to={lang +"/expertise"}>{lang? "نظرة عامة" : "OVERVIEW"}</Link>
                        <strong>{lang? "الخدمات" : "SERVICES"}</strong>
                        <Link to={lang +"/expertise/sectors"}>{lang? "القطاعات" : "SECTORS"}</Link>
                        <Link to={lang +"/expertise/locations"}>{lang? "المواقع" : "LOCATIONS"}</Link>
                    </div>
                );
                case "/ar/expertise/sectors":
                case "/expertise/sectors":
                return(
                    <div className="link-section">
                        <Link to={lang +"/expertise"}>{lang? "نظرة عامة" : "OVERVIEW"}</Link>
                        <Link to={lang +"/expertise/services"}>{lang? "الخدمات" : "SERVICES"}</Link>
                        <strong>{lang? "القطاعات" : "SECTORS"}</strong>
                        <Link to={lang +"/expertise/locations"}>{lang? "المواقع" : "LOCATIONS"}</Link>
                    </div>
                );
                case "/ar/expertise/sectors/:id":
                case "/expertise/sectors/:id":
                return(
                    <div className="link-section">
                        <Link to={lang +"/expertise"}>{lang? "نظرة عامة" : "OVERVIEW"}</Link>
                        <Link to={lang +"/expertise/services"}>{lang? "الخدمات" : "SERVICES"}</Link>
                        <Link to={lang +"/expertise/sectors"}><strong>{lang? "القطاعات" : "SECTORS"}</strong></Link>
                        <Link to={lang +"/expertise/locations"}>{lang? "المواقع" : "LOCATIONS"}</Link>
                    </div>
                );
                case "/ar/expertise/locations":
                case "/expertise/locations":
                return(
                    <div className="link-section">
                        <Link to={lang +"/expertise"}>{lang? "نظرة عامة" : "OVERVIEW"}</Link>
                        <Link to={lang +"/expertise/services"}>{lang? "الخدمات" : "SERVICES"}</Link>
                        <Link to={lang +"/expertise/sectors"}>{lang? "القطاعات" : "SECTORS"}</Link>
                        <strong>{lang? "المواقع" : "LOCATIONS"}</strong>
                    </div>
                );
                case "/ar/expertise/locations/:id":
                case "/expertise/locations/:id":
                return(
                    <div className="link-section">
                        <Link to={lang +"/expertise"}>{lang? "نظرة عامة" : "OVERVIEW"}</Link>
                        <Link to={lang +"/expertise/services"}>{lang? "الخدمات" : "SERVICES"}</Link>
                        <Link to={lang +"/expertise/sectors"}>{lang? "القطاعات" : "SECTORS"}</Link>
                        <Link to={lang +"/expertise/locations"}><strong>{lang? "المواقع" : "LOCATIONS"}</strong></Link>
                    </div>
                );
        }
    }

    renderContent = () => {
        const page = this.whatPage();
        switch(page) {
            default:
            case "/ar/expertise":
            case "/expertise":
                return <Overview />;
            case "/ar/expertise/services":
            case "/expertise/services":
                return <Services />;
            case "/ar/expertise/sectors":
            case "/expertise/sectors":
                return <Sectors />;
            case "/ar/expertise/locations":
            case "/expertise/locations":
                return <Locations />;
            case "/ar/expertise/locations/:id":
            case "/expertise/locations/:id":
                return <LocationSinglePage/>
            case "/ar/expertise/sectors/:id":
            case "/expertise/sectors/:id":
                return <SectorSinglePage/>
        }
    }

    getClassContentName = () => {
        const page = this.whatPage();
        switch(page) {
            default:
            case "/ar/expertise":
            case "/expertise":
                return "overview-page";
            case "/ar/expertise/services":
            case "/expertise/services":
                return "services-page";
            case "/ar/expertise/sectors":
            case "/expertise/sectors":
                return "sectors-page";
            case "/ar/expertise/locations":
            case "/expertise/locations":
                return "locations-page";
            case "/ar/expertise/locations/:id":
            case "/ar/expertise/sectors/:id":
            case "/expertise/locations/:id":
            case "/expertise/sectors/:id":
                return "details-single-page";
        }
    }

    render (){
        const {lang} = this.state;
        return (
            <div className="expertise-page">
                <PageSection>   
                        <Left>
                            <strong>{lang? "الخبرة" : "EXPERTISE"}</strong>
                            {this.renderLinks()}
                        </Left>
                        <Right className={this.getClassContentName()}>
                            {this.renderContent()}
                        </Right>
                </PageSection>
            </div>
        );
    }
}

export default withRouter(props => <Expertise {...props}/>);
