import React from 'react';
import {withRouter,Link} from "react-router-dom";



class NewsCard extends React.Component{

    state = {
        lang:"",
    }

    whatPage = () => {
        const {pathname} = this.props.location;
        return pathname;
    }

    setLang = (whatPage) => {
        let lang = 'en';
        if (whatPage.match(/^\/ar/gm)) { 
            lang = 'ar';
        }

        this.setState({lang: lang, langUrl:(lang==="en")? '' : '/ar'});

    }

    componentDidMount(){
        const whatPage = this.whatPage();
        this.setLang(whatPage);
    }

    render (){
        const {title, meta, info, link, image} = this.props;
        const {lang} = this.state;

        return (
            <div className="news-card">
                <div className="news-card-title">
                    <h2 className="bold">{title}</h2>
                </div>  
                <div className="news-card-text">
                    <p className="news-card-meta">{meta} |</p>
                    <p className="news-card-info">{info}</p>
                    <a className="news-card-link" target="_blank" rel="noreferrer" href={link}>{(lang === "en")? "Go to Page" :"قراءة المزيد"}</a>
                </div>
                <div className="news-card-image">
                    <img src={image} alt="news card img"/>
                </div>  
            </div>
        );
    }
}

export default withRouter(props => <NewsCard {...props}/>);

