import Media from './views/media';
import Firm from './views/firm';
import Careers from './views/careers';
import Contact from './views/contact';
import Home from './views';
import Expertise from './views/expertise';
import Investor from './views/investor';
import Governance from './views/governance';
import Properties from './views/properties';
import PropertySinglePage from './views/propertiesSinglePage';
import SearchPage from './views/search';

const routes = [    
    {
        path: "/ar/properties/:id/:slug",
        component: PropertySinglePage 
    },    
    {
        path: "/properties/:id/:slug",
        component: PropertySinglePage 
    },  
    {
        path: "/ar/properties/:id",
        component: PropertySinglePage 
    },    
    {
        path: "/properties/:id",
        component: PropertySinglePage 
    },  
    {
        path: "/ar/properties",
        component: Properties 
    },    
    {
        path: "/properties",
        component: Properties 
    },  
    {
        path: "/ar/governance/framework",
        component: Governance 
    },   
    {
        path: "/governance/framework",
        component: Governance 
    }, 
    {
        path: "/ar/governance/policies",
        component: Governance 
    },  
    {
        path: "/governance/policies",
        component: Governance 
    },  
    {
        path: "/ar/governance/complaints",
        component: Governance 
    },  
    {
        path: "/governance/complaints",
        component: Governance 
    },
    {  
        path: "/ar/governance",
        component: Governance 
    }, 
    {  
        path: "/governance",
        component: Governance 
    },  
    {
        path: "/ar/investor/annual-reports",
        component: Investor 
    },   
    {
        path: "/investor/annual-reports",
        component: Investor 
    },   
    {
        path: "/ar/investor/disclosures",
        component: Investor 
    },   
    {
        path: "/investor/disclosures",
        component: Investor 
    },   
    {
        path: "/ar/investor/credit-rating",
        component: Investor 
    },  
    {
        path: "/investor/credit-rating",
        component: Investor 
    },      
    {
        path: "/ar/investor/agm-documents",
        component: Investor 
    },      
    {
        path: "/investor/agm-documents",
        component: Investor 
    },      
    {
        path: "/ar/investor/shareholders",
        component: Investor 
    },      
    {
        path: "/investor/shareholders",
        component: Investor 
    },   
    {
        path: "/ar/investor/analyst",
        component: Investor 
    },     
    {
        path: "/investor/analyst",
        component: Investor 
    },  
    {
        path: "/ar/investor/calendar",
        component: Investor 
    },  
    {
        path: "/investor/calendar",
        component: Investor 
    },
    {
        path: "/ar/investor/candidate-application",
        component: Investor 
    },  
    {
        path: "/investor/candidate-application",
        component: Investor 
    },
    {
        path: "/ar/investor",
        component: Investor 
    },   
    {
        path: "/investor",
        component: Investor 
    },  
    {
        path: "/ar/expertise/locations/:id",
        component: Expertise
    },  
    {
        path: "/expertise/locations/:id",
        component: Expertise
    },
    {
        path: "/ar/expertise/locations",
        component: Expertise
    },
    {
        path: "/expertise/locations",
        component: Expertise
    },
    {
        path: "/ar/expertise/sectors/:id",
        component: Expertise
    },
    {
        path: "/expertise/sectors/:id",
        component: Expertise
    },
    {
        path: "/ar/expertise/sectors",
        component: Expertise
    },
    {
        path: "/expertise/sectors",
        component: Expertise
    },
    {
        path: "/ar/expertise/services",
        component: Expertise
    },
    {
        path: "/expertise/services",
        component: Expertise
    },
    {
        path: "/ar/expertise",
        component: Expertise
    },
    {
        path: "/expertise",
        component: Expertise
    },
    {
        path: "/ar/media/lifestyle/:id",
        component: Media
    }, 
    {
        path: "/media/lifestyle/:id",
        component: Media
    },    
    {
        path: "/ar/media/lifestyle",
        component: Media
    },     
    {
        path: "/media/lifestyle",
        component: Media
    },    
    {
        path: "/ar/media",
        component: Media
    },    
    {
        path: "/media",
        component: Media
    },
    {
        path: "/ar/firm/leadership/:id",
        component: Firm
    }, 
    {
        path: "/firm/leadership/:id",
        component: Firm
    },      
    {
        path: "/ar/firm/leadership",
        component: Firm
    },       
    {
        path: "/firm/leadership",
        component: Firm
    },     
    {
        path: "/ar/firm/awards",
        component: Firm
    },    
    {
        path: "/firm/awards",
        component: Firm
    }, 
    {
        path: "/ar/firm/social",
        component: Firm
    },  
    {
        path: "/firm/social",
        component: Firm
    },     
    {
        path: "/ar/firm/history",
        component: Firm
    },     
    {
        path: "/firm/history",
        component: Firm
    },     
    {
        path: "/ar/firm/sustainability",
        component: Firm
    },     
    {
        path: "/firm/sustainability",
        component: Firm
    },    
    {
        path: "/ar/firm",
        component: Firm
    },    
    {
        path: "/firm",
        component: Firm
    },
    {
        path: "/ar/careers",
        component: Careers
    },
    {
        path: "/careers",
        component: Careers
    },
    {
        path: "/ar/contact-us",
        component: Contact
    }, 
    {
        path: "/contact-us",
        component: Contact
    },  
    {
        path: "/ar/search",
        component: SearchPage
    }, 
    {
        path: "/search",
        component: SearchPage
    }, 
    {
        path: "/ar",
        component: Home
    },
    {
        path: "/",
        component: Home
    },
]


export default routes;