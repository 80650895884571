import React from 'react';
import {withRouter} from "react-router-dom";
import Api from './../../api/api';
import DescriptionCard from '../descriptionCard';
import Accordion from "../accordion";
import Loader from "react-loader-spinner";


class Social extends React.Component{


    state = {
        response: null,
        lang:"",
    }

    whatPage = () => {
        const {pathname} = this.props.location;
        return pathname;
    }

    setLang = (whatPage) => {
        let lang = 'en';
        if (whatPage.match(/^\/ar/gm)) { 
            lang = 'ar';
            document.title ="المسئولية الإجتماعية / الارجان / تطوير عقاري";
        }
        else{
            document.title ="SOCIAL RESPONSIBILITY / Alargan / Real Estate Development";
        }

        this.setState({lang: lang});
    }

    componentDidMount(){
        const whatPage = this.whatPage();
        this.setLang(whatPage);
        
        Api.getAllSocialResponsibilityData().then((response)=>{
            this.setState({response: response.data})
        });
        
    }

    createMarkup = (data) => {
        return {__html: data};
    }


    renderAccordion = (response, lang) => {
        const data =response.responsibility_socials.map((item) => {
            return {
                title:item["title_" + lang], 
                paragraph:<p className="bold" dangerouslySetInnerHTML={this.createMarkup( item["description_" + lang])}/>,
                
            };
        })

        return <Accordion data={data}/>;
    }

    render (){
        const {response, lang} = this.state;
        return (
            <>
                {(response)? 
                    <>
                        <div className="social-page-text">
                            <h2 className="bold" dangerouslySetInnerHTML={this.createMarkup(response.data[0]["value_" + lang])}/>
                        </div>
                        {this.renderAccordion(response, lang)}
                    </>
                    : <Loader className="loader" type="TailSpin" color="#80008D" height={100} width={100} />}
            </>
        );
    }
}

export default withRouter(props => <Social {...props}/>);
