import React from 'react';
import {withRouter} from "react-router-dom";
import Loader from "react-loader-spinner";
import Api from './../../api/api';
import DescriptionCard from '../descriptionCard';
import slugify from 'react-slugify';

class LocationSinglePage extends React.Component{

    state = {
        data: null,
        lang:"",
    }

    whatPage = () => {
        const {pathname} = this.props.location;
        return pathname;
    }

    getLocaionId = () => {
        const {id} = this.props.match.params;
        return id;
    }

    setLang = (whatPage) => {
        let lang = 'en';
        if (whatPage.match(/^\/ar/gm)) { 
            lang = 'ar';
        }

        this.setState({lang: lang});
    }

    componentDidMount(){
        const whatPage = this.whatPage();
        this.setLang(whatPage);
        
        Api.getSignleLocation(this.getLocaionId()).then((response)=>{
            this.setState({data: response.data})
        });
        
    }

    renderList = (response, lang) =>{
        const {data} = response;
        const listDetails = [
            {
                number:data[0]["value_" + lang],
                text:data[0]["name_" + lang]
            },
            {
                number:data[1]["value_" + lang],
                text:data[1]["name_" + lang]
            }
        ]

        return(
            <div className="list-wrapper">
                {listDetails.map((detail , i)=>{
                    const {number, text}= detail;
                    return (
                        <div className="list-details" key={"list-" + i}>
                            <span className="number">{number}</span>
                            <span className="text">{text}</span>
                        </div>
                    )
                })}
            </div>
        );
    }

    render (){
        const {data, lang} = this.state;
        return (
            <div className="details-single-page">
                {(data)? 
                    <>
                        <div className="details-container">
                            <h2 className="bold">{data.location["name_" + lang]}</h2>
                            <span>{data.location["description_" + lang]}</span>
                        </div>
                        {(data.data.length)?this.renderList(data, lang):''}
                        <DescriptionCard 
                            className="no-padding"
                            image={data.location.project.cover[0].image_url}
                            title={data.location.project["name_" + lang]}
                            info={data.location.project["featured_description_" + lang]}
                            link={"/properties/" + data.location.project.id + "/" + slugify(data.location.project["name_en"])}
                        />
                    </> 
                :<Loader className="loader" type="TailSpin" color="#80008D" height={100} width={100} />}
            </div>
        );
    }
}

export default withRouter(props => <LocationSinglePage {...props}/>);