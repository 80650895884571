import React from 'react';
import ArticleComponent from './../article-component';
import {withRouter} from "react-router-dom";
import Loader from "react-loader-spinner";
import Api from './../../api/api';

class Coverage extends React.Component{


    state = {
        data: null,
        lang:"",
    }

    whatPage = () => {
        const {pathname} = this.props.location;
        return pathname;
    }

    setLang = (whatPage) => {
        let lang = 'en';
        if (whatPage.match(/^\/ar/gm)) { 
            lang = 'ar';
            document.title ="تقارير المحللين / الارجان / تطوير عقاري";
        }
        else{
            document.title ="ANALYST COVERAGE / Alargan / Real Estate Development";
        }

        this.setState({lang: lang});
    }

    componentDidMount(){
        const whatPage = this.whatPage();
        this.setLang(whatPage);
        
        Api.getAllAnalystCoverages().then((response)=>{
            this.setState({data: response.data})
        });
        
    }

    renderCoverageDownloads = () => {
        const arr = [
            {
                title:"2015 ANALYST COVERAGE",
                link:"/#",
                linkIcon:"Download"
            },
            {
                title:"2015 ANALYST COVERAGE",
                link:"/#",
                linkIcon:"Download"
            },
            {
                title:"2014 ANALYST COVERAGE",
                link:"/#",
                linkIcon:"Download"
            },
            {
                title:"2015 ANALYST COVERAGE",
                link:"/#",
                linkIcon:"Download"
            },
            {
                title:"2015 ANALYST COVERAGE",
                link:"/#",
                linkIcon:"Download"
            }
        ];
        return (
            <div className="coverage-downloads-section">
                {arr.map((article,i)=>{
                    const {title, link, linkIcon}= article;
                    const className = (i === 0)? "first-item": "";
                    return (
                        <ArticleComponent
                            key={title + "-" + i}
                            title={title}
                            link={link}
                            linkIcon={linkIcon}
                            className={className}
                        />
                    )
                })}
            </div>
        );
    }

    render (){ 
        const {data, lang} = this.state;

        return (
            <div className="coverage-sections">
                {(data)? 
                    <>
                        {/* <h2 className="bold"></h2> */}
                        <span>{data.analyst_coverages_data[0]["value_" + lang]}</span>
                        <div className="coverage-downloads-section">
                            {data.analyst_coverages.map((item,i)=>{
                                const className = (i === 0)? "first-item": "";
                                return (
                                    <ArticleComponent
                                        key={item["title_" + lang] + "-" + i}
                                        title={item["title_" + lang]}
                                        link={item.download_link}
                                        linkIcon={(lang === "ar")? "تحميل":"Download"}
                                        className={className}
                                    />
                                )
                            })}
                        </div>
                    </>
                : <Loader className="loader" type="TailSpin" color="#80008D" height={100} width={100} />}
            </div>
        );
    }
}

export default withRouter(props => <Coverage {...props}/>);