import React from 'react';
import {withRouter} from "react-router-dom";
import Api from './../../api/api';
import Loader from "react-loader-spinner";

import MemberCard from './memberCard';


class Leadership extends React.Component{

    state = {
        data: null,
        lang:"",
    }

    whatPage = () => {
        const {pathname} = this.props.location;
        return pathname;
    }

    setLang = (whatPage) => {
        let lang = 'en';
        if (whatPage.match(/^\/ar/gm)) { 
            lang = 'ar';
            document.title ="الإدارة / الارجان / تطوير عقاري";
        }
        else{
            document.title ="Leadership / Alargan / Real Estate Development";
        }

        this.setState({lang: lang});
    }

    componentDidMount(){
        const whatPage = this.whatPage();
        this.setLang(whatPage);
        
        Api.getAllLeadershipMembersData().then((response)=>{
            this.setState({data: response.data})
        });
        
    }

    renderBoardMembers = (data, lang) => {
        const {leadership} = data;

        const boardMembers = leadership[0].leadership_members.map((member, i)=>{
            return{
                name:member["name_" + lang],
                title:member["role_" + lang],
                id:member.id,
                link:"/firm/leadership/"+ member.id
            }
        })

        return <MemberCard data={boardMembers}/>
    }

    
    createMarkup = (data) => {
        return {__html: data};
    }

    render (){

        const {data, lang} = this.state;
        return (
            <>
                {(data)? 
                <>
              
                    <div className="leadership-page-text">
                        <h2 className="bold" dangerouslySetInnerHTML={this.createMarkup(data.data[0]["value_" + lang])}/>
                    </div>
                    <div className="broad-members">
                        <h2 className="bold">{(lang ==="ar" )? "أعضاء مجلس الإدارة":"Board Members"}</h2>
                        {this.renderBoardMembers(data, lang)}
                    </div>
                    
                </>: <Loader className="loader" type="TailSpin" color="#80008D" height={100} width={100} />}
            </>
        );
    }
}

export default withRouter(props => <Leadership {...props}/>);